import React, {SVGProps} from 'react';

import {Appliances} from './ui-lib/icons/large';
import {
  AreaChart,
  Award,
  BarChart,
  Bell,
  Bookmark,
  Car,
  Charging,
  Cube,
  Electricity,
  Gas,
  Grid,
  InfoCircle,
  LineChart,
  Location as LocationIcon,
  Logout,
  PieChart,
  Redo,
  SmappeeLogoMark,
  SolarPanel,
  Standby,
  Sun,
  Tachometer,
  Threshold,
  Usage,
  Water
} from './ui-lib/icons/medium';
import {Report} from './ui-lib/icons/medium';
import {
  ArrowDown,
  ArrowUp,
  Bin,
  ChartNetwork,
  Clear,
  Contract,
  Copy,
  Download,
  Edit,
  ExternalLink,
  Hammer,
  FileExcel,
  FilePdf,
  Help,
  Key,
  Link,
  List,
  LockLocked,
  Plus,
  PowerOff,
  Search,
  SortAscending,
  SortDescending,
  SolidSitemap,
  Undo,
  Unlink,
  Users
} from './ui-lib/icons/small';
// import Hammer from './ui-lib/legacy-icons/Hammer';

// list of all our used Fontawesome icons
export enum Icon {
  ArrowDown = 'far fa-arrow-down',
  ArrowUp = 'far fa-arrow-up',
  Ban = 'fal fa-ban', // ok
  Edit = 'fal fa-edit', // ok
  ArrowToTop = 'fal fa-arrow-to-top',
  InfoCircle = 'fal fa-info-circle', // ok, medium
  AngleDown = 'fal fa-angle-down',
  ChartArea = 'fal fa-chart-area', // ok
  Award = 'fal fa-award', // ok
  ChartBar = 'fal fa-chart-bar', // ok
  Bell = 'fal fa-bell', // ok
  Bookmark = 'fal fa-bookmark', // ok
  Copy = 'fal fa-copy',
  Cube = 'fal fa-cube', // ok
  ChartLine = 'fal fa-chart-line', // ok
  ChartPie = 'fal fa-chart-pie', // ok
  ExternalLink = 'fal fa-external-link', // ok
  Trash = 'fal fa-trash', // ok
  Plus = 'fal fa-plus', // ok
  Pencil = 'fal fa-pencil', // ok
  Times = 'fal fa-times',
  Exclamation = 'fal fa-exclamation',
  List = 'fal fa-list', // ok
  ChartNetwork = 'fal fa-chart-network', // ok
  SignOut = 'fal fa-sign-out',
  Print = 'fal fa-print',
  Undo = 'fal fa-undo', // ok
  Link = 'fal fa-link', // ok
  Envelope = 'fal fa-envelope',
  SolidSquare = 'fas fa-square',
  PowerOff = 'far fa-power-off', // ok
  Eye = 'far fa-eye', // ok
  EyeSlash = 'far fa-eye-slash', // ok
  ArrowsV = 'fal fa-arrows-v', // ok
  SolidTint = 'fas fa-tint',
  SolidSitemap = 'fas fa-sitemap',
  Lock = 'fal fa-lock', // ok
  LockAlt = 'fal fa-lock-alt', // ok
  View = 'fal fa-search', // ok
  Redo = 'fal fa-redo',
  Square = 'far fa-square',
  CheckSquare = 'far fa-check-square',
  CheckSquareSolid = 'fas fa-check-square',
  Key = 'far fa-key',
  MoveInto = 'fas fa-sign-in',

  RegularTimesCircle = 'far fa-times-circle',
  RegularUnlink = 'far fa-unlink',
  Unlink = 'fal fa-unlink', // ok
  RegularExclamationTriangle = 'far fa-exclamation-triangle',

  SolidTimesCircle = 'fas fa-times-circle',
  SolidCheckCircle = 'fas fa-check-circle',
  Exchange = 'fas fa-exchange',

  Loading = 'fal fa-sync fa-spin',
  Car = 'fal fa-car',
  MapMarker = 'fal fa-map-marker-alt',
  Translations = 'far fa-language',

  Up = 'far fa-caret-up',
  Down = 'far fa-caret-down',
  Left = 'far fa-caret-left',
  Right = 'far fa-caret-right',
  Play = 'far fa-play',
  Stop = 'far fa-stop',

  ArrowLeft = 'far fa-arrow-left',
  ArrowRight = 'far fa-arrow-right',

  QuestionCircle = 'far fa-question-circle',
  FileImport = 'far fa-file-import',
  Hammer = 'far fa-hammer', // ok
  Contract = 'far fa-file-signature',
  Comment = 'far fa-comment',
  Download = 'far fa-download',
  Upload = 'far fa-cloud-upload',
  InCloud = 'far fa-cloud',
  FileCheck = 'fal fa-file-check',
  HourGlass = 'far fa-hourglass-half',
  DoNotEnter = 'fas fa-do-not-enter',
  ServiceDesk = 'fas fa-user-headset',
  ProfileRegular = 'fad fa-user',
  ProfilePartner = 'fad fa-user-tie',
  ProfileHelpDesk = 'fad fa-user-headset',
  Help = 'fal fa-question-circle',
  Pdf = 'far fa-file-pdf',
  Report = 'far fa-file-alt',
  Csv = 'far fa-file-excel',
  CircleUp = 'fas fa-arrow-circle-up',
  CircleDown = 'fas fa-arrow-circle-down',
  Shuffle = 'far fa-random',

  // Not fontawesome, but also icons
  Smappee = 'si-smappee', // ok
  Electricity = 'si-electricity', // ok
  Gas = 'si-gas', // ok
  Location = 'si-location', // ok
  SolarPanels = 'si-solarpanels', // ok
  Sun = 'si-solar', // ok
  Tachometer = 'fal fa-tachometer',
  Threshold = 'si-threshold', // ok
  Usage = 'si-usage', // ok
  Water = 'si-water', // ok
  AlwaysOn = 'si-always-on',
  Appliances = 'si-appliances',
  Notifications = 'si-notifications', // ok
  House = 'si-house', // ok
  Profile = 'si-profile', // ok
  Users = 'fal fa-users' // ok
}

export const Icons = {
  Clear: <i className={Icon.Ban} />,
  Delete: <i className={Icon.SolidTimesCircle} />,
  Edit: <i className={Icon.Edit} />,
  Upgrade: <i className={Icon.ArrowToTop} />,
  Unlink: <i className={Icon.RegularUnlink} />,
  Dropdown: <i className={Icon.AngleDown} />,
  ExternalLink: <i className={Icon.ExternalLink} />,
  Trash: <i className={Icon.Trash} />,
  Add: <i className={Icon.Plus} />,
  Pencil: <i className={Icon.Pencil} />,
  Times: <i className={Icon.Times} />,
  Exclamation: <i className={Icon.Exclamation} />,
  List: <i className={Icon.List} />,
  ChartNetwork: <i className={Icon.ChartNetwork} />,
  Move: <i className={Icon.SignOut} />,
  Print: <i className={Icon.Print} />,
  Reset: <i className={Icon.Undo} />,
  Link: <i className={Icon.Link} />,
  Envelope: <i className={Icon.Envelope} />,
  Notifications: <i className={Icon.Notifications} />,
  House: <i className={Icon.House} />,
  Profile: <i className={Icon.Profile} />,
  Loading: <span className="fal fa-sync fa-spin tw-mr-2" title="Loading data" />,
  Exchange: <i className={Icon.Exchange} />,
  Translations: <i className={Icon.Translations} />,
  FileImport: <i className={Icon.FileImport} />,
  Upload: <i className={Icon.Upload} />,

  Up: <i className={Icon.Up} />,
  Down: <i className={Icon.Down} />,
  Left: <i className={Icon.Left} />,
  Right: <i className={Icon.Right} />,
  Play: <i className={Icon.Play} />,
  Stop: <i className={Icon.Stop} />,

  ArrowRight: <i className={Icon.ArrowRight} />,
  ArrowLeft: <i className={Icon.ArrowLeft} />,

  Hammer: <i className={Icon.Hammer} />,

  Info: (props: {title: string}) => <i className={Icon.InfoCircle} title={props.title} />
};

export const SMAPPEE_FONT_ICON_MAP: {[key: string]: (props: SVGProps<SVGSVGElement>) => JSX.Element} = {
  'fal fa-trash': props => <Bin {...props} />,
  'fal fa-car': props => <Car {...props} />,
  'fal fa-link': props => <Link {...props} />,
  'fal fa-sign-out': props => <Logout {...props} />,
  'fal fa-sort-amount-down': props => <SortDescending {...props} />,
  'fal fa-sort-amount-up': props => <SortAscending {...props} />,
  'si-appliances': props => <Appliances {...props} />,
  'si-electricity': props => <Electricity {...props} />,
  'si-electricity-filled': props => <Grid {...props} />,
  'si-gas': props => <Gas {...props} />,
  'si-gas-filled': props => <Gas {...props} />,
  'si-always-on': props => <Standby {...props} />,
  'si-solar': props => <Sun {...props} />,
  'si-solar-filled': props => <Sun {...props} />,
  'si-water': props => <Water {...props} />,
  'si-water-filled': props => <Water {...props} />
};

export const SMAPPEE_ICON_MAP: {[key: string]: JSX.Element} = {
  ArrowDown: <ArrowDown />,
  ArrowUp: <ArrowUp />,
  Ban: <Clear />,
  Contract: <Contract />,
  ChartNetwork: <ChartNetwork />,
  Copy: <Copy />,
  Csv: <FileExcel />,
  Download: <Download />,
  Edit: <Edit />,
  ExternalLink: <ExternalLink />,
  Hammer: <Hammer />,
  Help: <Help />,
  InfoCircle: <InfoCircle />, // medium, @todo: small
  Key: <Key />,
  Link: <Link />,
  List: <List />,
  Lock: <LockLocked />,
  LockAlt: <LockLocked />,
  // MoveInto: <SignIn />,
  Pdf: <FilePdf />,
  Pencil: <Edit />,
  Plus: <Plus />,
  PowerOff: <PowerOff />,
  QuestionCircle: <Help />,
  Redo: <Redo height="16" width="16" />,
  Report: <Report height="16" width="16" />,
  Signout: <Logout height="16" width="16" />,
  SolidSitemap: <SolidSitemap />,
  Trash: <Bin />,
  Undo: <Undo />,
  Unlink: <Unlink />,
  Users: <Users />,
  View: <Search />
};

export const BOARD_ICON_MAP: {[key: string]: JSX.Element} = {
  'chart-area': <AreaChart />,
  award: <Award />,
  'chart-bar': <BarChart />,
  bell: <Bell />,
  bookmark: <Bookmark />,
  car: <Car />,
  cube: <Cube />,
  electricity: <Charging />,
  gas: <Gas />,
  'chart-line': <LineChart />,
  location: <LocationIcon />,
  'chart-pie': <PieChart />,
  smappee: <SmappeeLogoMark />,
  solarpanels: <SolarPanel />,
  sun: <Sun />,
  tachometer: <Tachometer />,
  threshold: <Threshold />,
  usage: <Usage />,
  water: <Water />
};
