import React, {useState} from 'react';

import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import DateInput from '../../components/inputs/DateInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {T} from '../../utils/Internationalization';
import {useObjectState} from '../../utils/ObjectState';
import {validateRFID} from '../../utils/Validation';

interface AssignRFIDTagsProps extends IPromiseModalProps<boolean> {
  locationId: number;
  tag?: string;
  comment: string;
  expiration?: number;
  title: string;
}

interface FormState {
  tag: string;
  comment: string;
  expiration: Date | null;
}

export default function AssignRFIDTags(props: AssignRFIDTagsProps) {
  const {locationId, title} = props;
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);
  const onToggle = () => resolve(false);

  const [addFailure, setAddFailure] = useState(false);
  const [formState, updateFormState] = useObjectState<FormState>({
    tag: props.tag || '',
    comment: props.comment,
    expiration: props.expiration === undefined ? null : new Date(props.expiration)
  });

  const handleClickSave = async () => {
    setAddFailure(false);
    const tag = (props.tag || formState.tag).replaceAll(':', '');
    try {
      await api.setRFIDChargingSettings(
        locationId,
        tag,
        formState.expiration ? formState.expiration.valueOf() : undefined,
        formState.comment
      );
      resolve(true);
    } catch {
      setAddFailure(true);
    }
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={onToggle}
      title={title}
      action={handleClickSave}
      actionText={props.tag ? T('chargingRFIDWhitelist.edit.btn.save') : T('chargingRFIDWhitelist.add.btn.save')}
      error={addFailure ? T('chargingStationPrivileges.couldNotAddTag') : undefined}
    >
      <div>
        {props.tag === undefined && (
          <TextInputGroup
            name="tag"
            label={T('chargingStationPrivileges.tag')}
            value={formState.tag}
            onChange={tag => updateFormState({tag})}
            validate={validateRFID}
          />
        )}
        <TextInputGroup
          name="comment"
          label={T('chargingStationPrivileges.column.comment')}
          value={formState.comment}
          onChange={comment => updateFormState({comment})}
          optional={true}
        />
        <DateInput
          name="expiration"
          label={T('chargingStationPrivileges.column.expiration')}
          info={T('validatedInput.optional')}
          value={formState.expiration}
          onChange={expiration => updateFormState({expiration})}
        />
      </div>
    </SingleActionModal>
  );
}
