import {T} from '../utils/Internationalization';
import {TimeRange} from '../utils/OpeningHours';

import {SupportedType} from './ActivationCode';
import {IAppliance} from './Appliance';
import {AuthUser} from './AuthUser';
import {IChargingStation} from './ChargingStation';
import {ChargingStationModel} from './ChargingStationModel';
import {IDevice} from './Device';
import {DeviceType} from './DeviceType';
import {ChannelProtocol} from './Load';
import {IOrganization} from './Organization';
import {IPricingPolicy} from './PricingPolicy';
import {IRetentionPolicy} from './RetentionPolicy';
import {TariffType} from './Tariff';
import {UsageUnit} from './UsageValue';

export const enum LocationType {
  Residential = 'RESIDENTIAL',
  Commercial = 'COMMERCIAL',
  Industrial = 'INDUSTRIAL'
}

export const enum SensorChannelType {
  Gas = 'GAS',
  Water = 'WATER',
  None = 'NONE',
  Output = 'OUTPUT',
  Electricity = 'ELECTRICITY',
  Custom = 'CUSTOM'
}

export const LOCATION_TYPES = [LocationType.Residential, LocationType.Commercial, LocationType.Industrial];

export function isBigConsumer(location: ILocationSummary | ILocation) {
  return (
    location.type === LocationType.Commercial ||
    location.type === LocationType.Industrial ||
    (location.chargingStation !== undefined && location.chargingStation.model === ChargingStationModel.UltraDualCable)
  );
}

export function getLocationTypeName(type: LocationType): string {
  switch (type) {
    case LocationType.Residential:
      return T('locationType.residential');
    case LocationType.Commercial:
      return T('locationType.commercial');
    case LocationType.Industrial:
      return T('locationType.industrial');
    default:
      return T('generic.unknown');
  }
}

export interface ICreateLocationRequest {
  name: string;
  type: LocationType;
  serialNumber: string;
  timeZone: string;
  latitude: number;
  longitude: number;
  electricityCurrency: string;
}

//Updates only the aspects of the service location that are provided in the body.
// Note that only name, latitude and longitude are taken into consideration.
export interface IUpdateLocationRequest {
  name?: string;
  type?: LocationType;
  nilm?: number;
  timeZoneId?: string;
  electricityCurrency?: string;
  latitude?: number;
  longitude?: number;
  parentId?: number;
  openingsHours?: TimeRange[];
  functionType?: LocationFunctionType;
  address?: {
    streetAndNumber: string;
    postalCode: string;
    city: string;
    country: string;
  };
}

export interface ILocationUser {
  id: number;
  firstName: string;
  lastName?: string;
  emailAddress: string;
  role: 'ADMIN' | 'OBSERVER';
  userName: string;
}

export const enum LocationFunctionType {
  None = '_', // only used by dashboard, never sent from cloud. not persisted anywhere

  Standard = 'DEFAULT',
  Parent = 'PARENT',
  ChargingPark = 'CHARGINGPARK',
  ChargingStation = 'CHARGINGSTATION',
  LocalityParent = 'LOCALITY_PARENT',
  LocalityParentChargingPark = 'LOCALITY_PARENT_CHARGING_PARK'
}

export function translateFunctionType(type: LocationFunctionType): string {
  switch (type) {
    case LocationFunctionType.Standard:
      return T('locationFunctionType.default');
    case LocationFunctionType.LocalityParent:
      return T('locationFunctionType.multiGatewayParent');
    case LocationFunctionType.ChargingStation:
      return T('locationFunctionType.chargingStation');
    case LocationFunctionType.ChargingPark:
    case LocationFunctionType.Parent:
      return T('locationFunctionType.chargingPark');
    case LocationFunctionType.LocalityParentChargingPark:
      return T('locationFunctionType.multiGatewayChargingPark');
    default:
      return type;
  }
}

export function isChargingParent(type: LocationFunctionType | undefined) {
  return (
    type === LocationFunctionType.Parent ||
    type === LocationFunctionType.ChargingPark ||
    type === LocationFunctionType.LocalityParentChargingPark
  );
}

export function isLocalityParent(type: LocationFunctionType | undefined) {
  return type === LocationFunctionType.LocalityParentChargingPark || type === LocationFunctionType.LocalityParent;
}

export function hasChildLocations(type: LocationFunctionType | undefined) {
  return (
    type === LocationFunctionType.Parent ||
    type === LocationFunctionType.ChargingPark ||
    type === LocationFunctionType.LocalityParentChargingPark ||
    type === LocationFunctionType.LocalityParent
  );
}

export function isEVWallLocation(location: ILocationSummary) {
  return location.functionType === LocationFunctionType.Standard && location.chargingStation !== undefined;
}

export const enum LocationFeature {
  HomeControl = 'HOMECONTROL',
  SmartDevices = 'SMARTDEVICES',
  OutputModules = 'OUTPUT_MODULES',
  DeleteOutputModule = 'DELETE_OUTPUT_MODULE',
  EditOutputModule = 'EDIT_OUTPUT_MODULE',
  Appliances = 'APPLIANCES',
  Integrations = 'INTEGRATIONS',
  SmappeeMonitors = 'SMAPPEE_MONITORS',
  ChargingStations = 'CHARGING_STATIONS',
  Tariffs = 'TARIFFS',
  LoadConfig = 'LOAD_CONFIG'
}

export interface ILocation extends ILocationSummary {
  hasGas?: boolean;
  hasSolar?: boolean;
  hasWater?: boolean;
  newAppliances: boolean;
  organization?: IOrganization;
  retention: IRetentionPolicy;
  supportedDeviceTypes: SupportedType[];
  obsoleteTimestamp?: number;
  macAaddress?: string;
  address?: ChargingStationAddress;
  calendarType?: TariffType;
  hasChargingStations: boolean;

  chargingStations?: ILocation[];
  features: LocationFeature[];
}

export interface ChargingStationAddress {
  streetAndNumber: string;
  postalCode: string;
  city: string;
  country: string;
}

export interface IRoleType {
  id: string;
  name: string;
}
export interface ILocationSummary {
  id: number;
  deviceType?: DeviceType;
  electricityCurrency: string;
  functionType: LocationFunctionType;
  chargingStation?: {
    serialNumber: string;
    model: ChargingStationModel;
    smappeePublicChargingEnabled?: boolean;
  };
  ipaddress?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  serialNumber?: string;
  timeZoneId: string;
  openingHours?: TimeRange[];
  type: LocationType;
  uuid: string;
  historicDevice: boolean;
  writeAccess?: boolean;

  installationDate?: number;
  closingDate?: number;

  chargingSettings?: {
    parentPricingPolicy?: IPricingPolicy;
    pricingPolicy?: IPricingPolicy;
  };

  parentId?: number; // absent when loading the list of locations, but generated locally where necessary
}

export interface IChildLocation extends ILocationSummary {
  devices?: IDevice[];
}

export interface ILocationSearchResult {
  serviceLocationId: number;
  name?: string;
  serialNumber?: string;
  active: boolean;
  obsolete: boolean;
  functionType: LocationFunctionType;
  chargingStation?: {
    serialNumber: string;
    model: ChargingStationModel;
  };
}

export function isSameSearchResult(a: ILocationSearchResult, b: ILocationSearchResult) {
  return a.serviceLocationId === b.serviceLocationId && a.serialNumber === b.serialNumber;
}

export interface IRate {
  dayType: number;
  electricityCost: number;
  fromTime: number;
  gasCost: number;
  inactive: boolean;
  rateId: number;
  rateType: number;
  solarCost: number;
  toTime: number;
  waterCost: number;
}

export interface ILocationMetaInfo {
  allowedActions: string[];
  appliances: IAppliance[];
  currentRateId: number;
  deviceType: number;
  electricityCost: number;
  electricityCostCurrency: string;
  hasGas: boolean;
  hasWater: boolean;
  messagingProtocol: ChannelProtocol;
  name: string;
  rates: IRate[];
  registered: number;
  serialNumber: string;
  serviceLocationUuid: string;
  timezone: string;
  uuid: string;
  sensors?: IGasWaterDeviceFromMeta[];
}

export interface IGasWaterDeviceFromMeta {
  id: number;
  serialNumber: string;
  read: boolean;
  channels: IGasWaterChannelFromMeta[];
}

export interface IGasWaterDevice {
  id: number;
  name: string;
  firmwareVersion: string;
  macAddress: string;
  serialNumber: string;
  standAlone: boolean;
  lastReading: {timestamp?: number}; // I'm not sure if timestamp is always filled in or not, setting it as optional just to be sure. To be tested with a gas&water which hasn't ever read data
  inputChannels: IGasWaterChannel[];
}

export interface IGasWaterChannel {
  type: SensorChannelType;
  enabled: boolean;
  id: number;
  leak?: {hours: number};
  excessive?: {consumption: number};
  leakLevel: LeakLevel;
  measuringType: MeasuringType;
  name: string;
  pulsesPerUnit: number;
  status: GasWaterChannelStatus;
  unit: UsageUnit;
}

export function getChannelTypeLabel(type: SensorChannelType): string {
  switch (type) {
    case SensorChannelType.None:
      return T('device.channelType.notConnected');
    case SensorChannelType.Gas:
      return T('device.channelType.gas');
    case SensorChannelType.Water:
      return T('device.channelType.water');
    case SensorChannelType.Output:
      return T('device.channelType.output');
    case SensorChannelType.Electricity:
      return T('device.channelType.electricity');
    case SensorChannelType.Custom:
      return T('device.channelType.custom');
    default:
      return type;
  }
}

export const enum LeakLevel {
  None = 'NONE',
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export const enum GasWaterChannelStatus {
  Ok = 'OK',
  Leaking = 'LEAKING',
  ExcessiveConsumption = 'EXCESSIVE_CONSUMPTION',
  LeakingAndExcessiveConsumption = 'LEAKING_AND_EXCESSIVE_CONSUMPTION'
}

export const enum MeasuringType {
  Optical = 'OPTICAL',
  Magnetic = 'MAGNETIC'
}

export const enum GasWaterTypeInt {
  Gas = 0,
  Water = 1
}

// some of these fields are definitely enums, but I don't have their definitions handy
export interface IGasWaterChannelFromMeta {
  multiplier: number;
  channel: number;
  threshold: number;
  enabled: boolean;
  sensorId: number;
  leak: number;
  uom: number;
  pulses: number;
  name: string;
  measuringType: GasWaterTypeInt;
  optical: boolean;
  status: number;
}

export function getChannelUsageUnit(channel: IGasWaterChannelFromMeta) {
  switch (channel.uom) {
    case 0:
      return UsageUnit.CubicMeter;
    case 1:
      return UsageUnit.Liter;
    case 2:
      return UsageUnit.CubicFoot;
    case 3:
      return UsageUnit.Gallon;
    case 4:
      return UsageUnit.KiloWattHour;
    default:
      return UsageUnit.Unknown;
  }
}

export interface IDeviceActivation {
  serialNumber: string;
  role: string;
  fromTime: number;
  ipAddress: string;
  serviceLocationID: number;
  currentFirmwareVersion: string;
  url: string;
  targetFirmwareVersion: string;

  meta?: ILocationMetaInfo;
}

export function locationSummaryToSearchResult(summary: ILocationSummary): ILocationSearchResult {
  return {
    name: summary.name,
    serviceLocationId: summary.id,
    active: summary.serialNumber !== undefined && !summary.historicDevice,
    serialNumber: summary.serialNumber,
    obsolete: false,
    functionType: summary.functionType || LocationFunctionType.Standard,
    chargingStation: summary.chargingStation
  };
}

export function chargingStationToSearchResult(station: IChargingStation): ILocationSearchResult {
  return {
    name: station.name,
    serviceLocationId: station.serviceLocation!.id,
    active: true,
    serialNumber: station.serialNumber,
    obsolete: false,
    functionType: LocationFunctionType.ChargingStation,
    chargingStation: {
      serialNumber: station.serialNumber,
      model: station.model
    }
  };
}

export function optionalLocationToSummary(location: ILocation | undefined): ILocationSummary | undefined {
  if (!location) return undefined;

  return locationToSummary(location);
}

export function locationToSummary(location: ILocation): ILocationSummary {
  return {
    id: location.id,
    deviceType: location.deviceType,
    electricityCurrency: location.electricityCurrency,
    chargingStation: location.chargingStation,
    functionType: location.functionType,
    ipaddress: location.ipaddress,
    latitude: location.latitude,
    longitude: location.longitude,
    name: location.name,
    serialNumber: location.serialNumber,
    timeZoneId: location.timeZoneId,
    type: location.type,
    uuid: location.uuid,
    historicDevice: location.serialNumber === undefined,
    writeAccess: true, // locationToSummary is used when loading individual locations as service desk user, so we assume write access

    parentId: location.parentId
  };
}

export function hasChargingStationFunctionality(location: ILocationSummary | ILocation) {
  return location.chargingStation !== undefined || location.functionType === LocationFunctionType.ChargingStation;
}

export function isLocalityChild(location: ILocationSummary | ILocation) {
  return location.parentId !== undefined && location.chargingStation === undefined;
}

export function isReadOnly(user: AuthUser, location: ILocationSummary | undefined) {
  return location === undefined || location.writeAccess === false;
}

export interface LocationResponsibilities {
  cpo: LocationResponsibility | undefined;
  installers: LocationResponsibility[];
}

export interface LocationResponsibility {
  from: number;
  organization: IOrganization;
}
