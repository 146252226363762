import {T} from '../utils/Internationalization';

import {IOrganization} from './Organization';

export const enum SupportedType {
  Pro = 'PRO',
  Plus = 'PLUS',
  Energy = 'ENERGY',
  Solar = 'SOLAR',
  Infinity = 'SMAPPEE2',
  WifiConnect = 'WIFI_CONNECT'
}

export const SupportedTypes = [
  SupportedType.Energy,
  SupportedType.Solar,
  SupportedType.Infinity,
  SupportedType.Pro,
  SupportedType.Plus,
  SupportedType.WifiConnect
];

export const enum SupportedFeature {
  DataAnalysis = 'DATA_ANALYSIS',
  OverloadProtection = 'OVERLOAD_PROTECTION',
  MinMaxCurrent = 'MIN_MAX_CURRENT',
  MinMaxVoltage = 'MIN_MAX_VOLTAGE'
}

export const SupportedFeatures = [
  SupportedFeature.OverloadProtection,
  SupportedFeature.MinMaxCurrent,
  SupportedFeature.MinMaxVoltage
];

export function getFeatureDisplayName(type: SupportedFeature): string {
  switch (type) {
    case SupportedFeature.DataAnalysis:
      return T('feature.dataAnalysis');
    case SupportedFeature.OverloadProtection:
      return T('feature.overloadProtection');
    case SupportedFeature.MinMaxCurrent:
      return T('feature.minMaxCurrent');
    case SupportedFeature.MinMaxVoltage:
      return T('feature.minMaxVoltage');
    default:
      return type;
  }
}

export interface IActivation {
  timestamp: number;
  serialNumber: string;
  stationSerialNumber?: string;
  stationArticleCode?: string;
  orderNumber?: string;
}

export interface IActivationCodeList {
  organizations: IOrganizationActivationCodes[];
}

export interface IOrganizationActivationCodes {
  id: number;
  name: string;
  activationCodes: IOrganizationActivationCode[];
}

export interface IOrganizationActivationCode {
  id: number;
  name?: string;
  code: string;
  reusable: boolean;
  supportedTypes: SupportedType[];
  supportedFeatures?: SupportedFeature[];
  contractYears: number;
  currentHarmonics?: number[];
  voltageHarmonics?: number[];
  subOrganizationId?: number;
  subOrganizationName?: string;
  isDefault?: boolean; // Cloud 1.19+
}

export interface IActivationCode extends IOrganizationActivationCode {
  organizationId?: number; // present on /servicelocation/xxx/activationcode
  organization: IOrganization;
}

export interface IActivationHistory extends IActivationCode {
  history: IActivation[];
}

export interface ICreateActivationCodeRequest {
  organizationId?: number;
  name?: string; // required for reusables
  reusable: boolean;
  supportedTypes: SupportedType[];
  contractYears: number;
  features: SupportedFeature[];
  currentHarmonics?: number[];
  voltageHarmonics?: number[];
  count?: number; // non-reusable only
  isDefault?: boolean;
}

export interface IUpdateActivationCodeRequest {
  name?: string;
  contractYears?: number;
  supportedTypes?: SupportedType[];
  supportedFeatures?: SupportedFeature[];
  currentHarmonics?: number[];
  voltageHarmonics?: number[];
  isDefault?: boolean;
}

export const enum AssignActivationCodeDeviceFailure {
  AlreadyAssigned = 'ALREADY_ASSIGNED',
  DoesNotExist = 'DOES_NOT_EXIST'
}

export const enum AssignActivationCodeGeneralFailure {
  ActivationCodeNotReusable = 'ACTIVATION_CODE_IS_NOT_RESUSABLE',
  ActivationCodeNotFound = 'ACTIVATION_CODE_NOT_FOUND',
  SQLError = 'SQL_ERROR'
}

export interface IAssignActivationCodesResponse {
  deviceFailures?: {
    serialNumber: string;
    reason: AssignActivationCodeDeviceFailure;
  }[];
  generalFailure?: AssignActivationCodeGeneralFailure;
}

export function getActivationCodesForOrganization(
  ownActivationCodes: IOrganizationActivationCode[],
  parentActivationCodes: IOrganizationActivationCode[],
  organization: IOrganization,
  includeSub: boolean
): IActivationCode[] {
  const items: IActivationCode[] = [];

  for (let activationcode of parentActivationCodes) {
    if (activationcode.subOrganizationId !== undefined) {
      continue;
    }
    const activationCodeWithOrganization = {
      ...activationcode,
      organization: {
        id: organization.id,
        name: organization.parentName || organization.name,
        parentId: undefined,
        parentName: undefined
      } as IOrganization
    };
    items.push(activationCodeWithOrganization);
  }
  for (let activationCode of ownActivationCodes) {
    if (activationCode.subOrganizationId !== undefined && !includeSub) {
      continue;
    }
    const activationCodeWithOrganization = {
      ...activationCode,
      organization: {
        id: organization.id,
        name: organization.name,
        parentId: organization.parentId,
        parentName: organization.parentName
      } as IOrganization
    };
    items.push(activationCodeWithOrganization);
  }
  return items;
}

export function getAvailableActivationCodesForOrganization(
  ownActivationCodes: IOrganizationActivationCode[],
  parentActivationCodes: IOrganizationActivationCode[],
  organization: IOrganization
): IActivationCode[] {
  const items: IActivationCode[] = [];

  for (let activationcode of parentActivationCodes) {
    if (activationcode.subOrganizationId !== undefined) {
      continue;
    }
    const activationCodeWithOrganization = {
      ...activationcode,
      organization: {
        id: organization.id,
        name: organization.parentName || organization.name
      } as IOrganization
    };
    items.push(activationCodeWithOrganization);
  }
  for (let activationCode of ownActivationCodes) {
    if (activationCode.subOrganizationId !== undefined) {
      continue;
    }
    const activationCodeWithOrganization = {
      ...activationCode,
      organization: {
        id: organization.id,
        name: organization.name,
        parentId: organization.parentId,
        parentName: organization.parentName
      } as IOrganization
    };
    items.push(activationCodeWithOrganization);
  }
  return items;
}

export function getMappedActivationCodesForOrg(
  activationCodes: IOrganizationActivationCodes,
  organization: IOrganization
): Partial<IActivationCode> {
  return {
    organization: {
      id: organization?.id,
      name: organization?.name
    } as IOrganization,
    activationCodes: [...activationCodes.activationCodes]
  } as unknown as Partial<IActivationCode>;
}

export function findActivationCode(
  activationCodes: IOrganizationActivationCodes[],
  activationCodeId: number
): [IOrganizationActivationCode | undefined, number | undefined] {
  for (let organization of activationCodes) {
    const code = organization.activationCodes.find(x => x.id === activationCodeId);
    if (code) return [code, organization.id];
  }

  return [undefined, undefined];
}

export function getOrganizationName(organization: IOrganization) {
  if (organization.parentName) {
    return `${organization.parentName} / ${organization.name}`;
  } else {
    return organization.name;
  }
}
