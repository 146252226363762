import {hasFeature, AppFeature} from '../utils/AppParameters';

import ActivationCodeLocations from './ActivationCodeLocations';
import ActivationCodes from './ActivationCodes';
import Advenir from './Advenir';
import Appliances from './Appliances';
import AppliancesMini from './AppliancesMini';
import AppManagement from './AppManagement';
import SmappeeBess from './Bess';
import {ICardType} from './CardType';
import ChargingConnectorQuickView from './ChargingConnectorQuickView';
import ChargingRFIDWhitelist from './ChargingRFIDWhitelist';
import ChargingSessions from './ChargingSessions';
import ChargingSolarOptimization from './ChargingSolarOptimization';
import ChargingStationAutomations from './ChargingStationAutomations';
import ChargingStationComponents from './ChargingStationComponents';
import ChargingStationConfiguration from './ChargingStationConfiguration';
import ChargingStationConsumption from './ChargingStationConsumption';
import ChargingStationDiagnostics from './ChargingStationDiagnostics';
import ChargingStationsOverview from './ChargingStationOverview';
import ChargingStationPriorities from './ChargingStationPriorities';
import ChargingStationPrivileges from './ChargingStationPrivileges';
import ChargingStations from './ChargingStations';
import ChargingStationTemperature from './ChargingStationTemperature';
import ChargingTransactions from './ChargingStationTransactions';
import Contracts from './Contracts';
import CPOChargingSessions from './CPOChargingSessions';
import CPOChargingSquares from './CPOChargingSquares';
import CreateAPIKey from './CreateAPIKey';
import CreateChargingStations from './CreateChargingStations';
import FluviusMeasurementCampaigns from './custom/FluviusMeasurementCampaigns';
import SibelgaMeasurementCampaigns from './custom/SibelgaMeasurementCampaigns';
import DailyMeterReadings from './DailyMeterReadings';
import DeviceActivationHistory from './DeviceActivationHistory';
import DeviceConfiguration from './DeviceConfiguration';
import DeviceConfigurationHistory from './DeviceConfigurationHistory';
import DeviceConfigurationLegacy from './DeviceConfigurationLegacy';
import DeviceIncidents from './DeviceIncidents';
import DeviceManagement from './DeviceManagement';
import DeviceOverview from './DeviceOverview';
import ElectricityValues from './ElectricityValues';
import Events from './Events';
import ExportChargingSessions from './ExportChargingSessions';
import Firmware from './Firmware';
import FutechImporter from './FutechImporter';
import GasValues from './GasValues';
import GasWaterReadings from './GasWaterReadings';
import Harmonics from './Harmonics';
import InputModuleValues from './InputModuleValues';
import Invoices from './Invoices';
import LiveElectricityValues from './LiveElectricityValues';
import LiveHarmonics from './LiveHarmonics';
import LiveValues from './LiveValues';
import LiveValuesMini from './LiveValuesMini';
import CTConfiguration from './LoadConfiguration';
import LocationConfiguration from './LocationConfiguration';
import LocationMap from './LocationMap';
import Locations from './Locations';
import ManageUserRegions from './ManageRegionUsers';
import Messages from './Messages';
import NotFound from './NotFound';
import Organizations from './Organizations';
import OrganizationUsers from './OrganizationUsers';
import OverloadProtection from './OverloadProtection';
import PartnerInfo from './PartnerInfo';
import PartnerInfoMini from './PartnerInfoMini';
import PhasorDisplay from './PhasorDisplay';
import Preconfiguration from './Preconfiguration';
import PricingGroups from './PricingGroups';
import PricingPolicies from './PricingPolicies';
import PublicChargingTokens from './PublicChargingTokens';
import RemoteConnect from './RemoteConnect';
import RetentionPolicy from './RetentionPolicy';
import SendPushMessage from './SendPushMessage';
import Services from './Services';
import SharedUsers from './SharedUsers';
import Shipment from './Shipment';
import SmartDevices from './SmartDevices';
import SpecialFunctions from './SpecialFunctions';
import SplitBilling from './SplitBilling';
import SplitBillingOnStation from './SplitBillingOnStation';
import SuggestionsMini from './SuggestionsMini';
import SurveyResults from './SurveyResults';
import SwitchValues from './SwitchValues';
import ThirdPartyChargingStation from './ThirdPartyChargingStation';
import UserChargingSessions from './UserChargingSessions';
import UserLocations from './UserLocations';
import Users from './Users';
import WaterValues from './WaterValues';

export const CARD_TYPES: ICardType<any>[] = [
  ActivationCodes,
  ActivationCodeLocations,
  Advenir,
  Appliances,
  AppliancesMini,
  AppManagement,
  ChargingConnectorQuickView,
  ChargingRFIDWhitelist,
  ChargingSessions,
  ChargingStations,
  ChargingStationAutomations,
  ChargingStationComponents,
  ChargingStationConfiguration,
  ChargingStationConsumption,
  ChargingStationDiagnostics,
  ChargingStationPriorities,
  ChargingStationPrivileges,
  ChargingStationTemperature,
  ChargingStationsOverview,
  ChargingTransactions,
  Contracts,
  CPOChargingSessions,
  CPOChargingSquares,
  CreateAPIKey,
  CreateChargingStations,
  CTConfiguration,
  DailyMeterReadings,
  DeviceActivationHistory,
  DeviceConfiguration,
  DeviceConfigurationLegacy,
  DeviceConfigurationHistory,
  DeviceIncidents,
  DeviceManagement,
  DeviceOverview,
  ElectricityValues,
  Events,
  ExportChargingSessions,
  Firmware,
  FluviusMeasurementCampaigns,
  FutechImporter,
  GasValues,
  GasWaterReadings,
  Harmonics,
  InputModuleValues,
  Invoices,
  LiveElectricityValues,
  LiveHarmonics,
  LiveValues,
  LiveValuesMini,
  LocationConfiguration,
  Locations,
  LocationMap,
  ManageUserRegions,
  Messages,
  Organizations,
  OrganizationUsers,
  OverloadProtection,
  PartnerInfo,
  PartnerInfoMini,
  PhasorDisplay,
  PublicChargingTokens,
  Preconfiguration,
  PricingPolicies,
  PricingGroups,
  RemoteConnect,
  RetentionPolicy,
  SendPushMessage,
  Services,
  SharedUsers,
  Shipment,
  SibelgaMeasurementCampaigns,
  SmappeeBess,
  SmartDevices,
  SpecialFunctions,
  SplitBilling,
  SplitBillingOnStation,
  SuggestionsMini,
  SurveyResults,
  SwitchValues,
  ThirdPartyChargingStation,
  Users,
  UserChargingSessions,
  UserLocations,
  WaterValues
];

export const NOT_FOUND: ICardType<any> = NotFound;

if (hasFeature(AppFeature.SolarOptimizationChart)) {
  CARD_TYPES.push(ChargingSolarOptimization);
}

function indexCardTypes() {
  const result: {[key: string]: ICardType<any>} = {};
  for (let cardType of CARD_TYPES) {
    result[cardType.type] = cardType;
  }
  return result;
}
const cardTypeByKey = indexCardTypes();

export function getCardType(type: string): ICardType<any> {
  return cardTypeByKey[type] || NOT_FOUND;
}
