import React from 'react';
import {Form} from 'reactstrap';

import {TextInputGroup} from '../../components/inputs/TextInput';
import {Checkbox} from '../../components/ui/checkbox';
import {T} from '../../utils/Internationalization';
import {FilterPanelButton} from '../components/actions/FilterPanel';

interface FilterProps {
  hasFilter: boolean;
  showPending: boolean;
  showActive: boolean;
  showClosed: boolean;
  employeeNumber: string;
  setShowPending: (value: boolean) => void;
  setShowActive: (value: boolean) => void;
  setShowClosed: (value: boolean) => void;
  setEmployeeNumber: (value: string) => void;
}

export default function Filter(props: FilterProps) {
  const {
    hasFilter,
    showPending,
    showActive,
    showClosed,
    employeeNumber,
    setShowPending,
    setShowActive,
    setShowClosed,
    setEmployeeNumber
  } = props;

  return (
    <FilterPanelButton hasFilter={hasFilter}>
      <Form>
        <Checkbox
          id="filter-pending"
          name="filter-pending"
          label={T('splitBilling.status.pending')}
          checked={showPending}
          onCheckedChange={setShowPending}
          testId="filter-pending"
        />
        <Checkbox
          id="filter-active"
          name="filter-active"
          label={T('splitBilling.status.active')}
          checked={showActive}
          onCheckedChange={setShowActive}
          testId="filter-active"
        />
        <Checkbox
          id="filter-closed"
          name="filter-closed"
          label={T('splitBilling.status.closed')}
          checked={showClosed}
          onCheckedChange={setShowClosed}
          testId="filter-closed"
        />
        <TextInputGroup
          name="employeeNumber"
          label={T('splitBilling.field.employeeNumber')}
          value={employeeNumber}
          onChange={setEmployeeNumber}
        />
      </Form>
    </FilterPanelButton>
  );
}
