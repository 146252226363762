import type {SVGProps} from 'react';
const SvgGrid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    fillRule="evenodd"
    {...props}
  >
    <path d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM7.5 6.857c0-.592.48-1.071 1.071-1.071h5.143a1.071 1.071 0 0 1 .758 1.829l-6.857 6.857A1.072 1.072 0 0 1 6.1 12.957l5.028-5.028H8.57c-.591 0-1.071-.48-1.071-1.072H7.5Zm10.4 4.186a1.072 1.072 0 0 0-1.515-1.515l-6.857 6.857a1.071 1.071 0 0 0 .758 1.83h5.143a1.072 1.072 0 0 0 0-2.144h-2.557l5.028-5.028Z" />
  </svg>
);
export default SvgGrid;
