import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import PeriodSelector, {
  PeriodSettings,
  Period,
  PeriodRoundingMode,
  usePeriodRangeForTimezone
} from '../../components/PeriodSelector';
import Table, {IPersistedTableSettings, SortOrder} from '../../components/Table';
import {Button} from '../../components/ui/button';
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from '../../components/ui/select';
import {Clear, Hammer} from '../../components/ui-lib/icons/small';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {ChargingStationPaymentType, IChargingSession} from '../../models/ChargingStation';

import {IOrganization} from '../../models/Organization';
import {Interval} from '../../models/UsageValue';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {None} from '../../utils/Arrays';
import {useOrganization} from '../../utils/FunctionalData';
import {useAutoRefresh, useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation, useCardColumnSettings, useUser} from '../CardUtils';
import {FixSessionsModal} from '../ChargingStationTransactions/FixSessionsModal';
import {CardActions} from '../components';
import {ExportCsv, Reload} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {getTableColumns} from './Columns';

interface IChargingSessionsSettings extends ICardSettings {
  table: IPersistedTableSettings;
  period: PeriodSettings;
  organizationId?: number;
  paymentType?: ChargingStationPaymentType;
}

const rowKey = (session: IChargingSession) => session.uuid;

const ChargingSessions = (props: ICardProps<IChargingSessionsSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {paymentType} = settings;

  const location = useCardLocation(settings);
  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [tableKey, setTableKey] = useState(0);
  const isBasicuser = useUser().isRegularUser();
  const userId = useUser().userId;
  const [selected, setSelected] = useState<IChargingSession[]>([]);
  const modals = useModals();

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = settings.organizationId || inputOrganizations.defaultOrganization?.id;
  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, organizationId);

  const activePeriod = usePeriodRangeForTimezone(
    settings.period,
    location ? location.timeZoneId : 'Europe/Brussels',
    PeriodRoundingMode.INCLUSIVE
  );

  const [sessions, refreshSessions] = useCardLoader(
    api => {
      if (!activePeriod) return Promise.resolve(None);
      else if (isBasicuser) {
        return api.user.getUserChargingSessions(userId, activePeriod.from, activePeriod.to);
      } else if (organizationId) {
        return api.organizations.getChargingSessions(organizationId, activePeriod.from, activePeriod.to);
      } else return Promise.resolve(None);
    },
    [activePeriod?.from, activePeriod?.to, organizationId, isBasicuser, userId],
    plural('chargingSession'),
    None
  );

  const usernames = useMemo(() => {
    let names = new Set<string>();
    sessions.forEach(session => session.userName && names.add(session.userName));
    return [...names].sort();
  }, [sessions]);

  useEffect(() => {
    if (username && !usernames.includes(username)) setUsername(undefined);
  }, [username, usernames]);

  const refresh = () => {
    refreshSessions();
  };

  const handleClearSelection = useCallback(() => {
    setSelected([]);
    setTableKey(key => key + 1);
  }, []);

  const handleFixSelected = useCallback(() => {
    modals.show(props => <FixSessionsModal sessions={selected} {...props} />).then(() => refreshSessions());
  }, [modals, refreshSessions, selected]);

  const emails = React.useMemo(() => {
    let emails = new Set<string>();
    sessions.forEach(session => session.user?.emailAddress && emails.add(session.user?.emailAddress));
    return [...emails].sort();
  }, [sessions]);

  useAutoRefresh(refresh);

  const columns = useMemo(() => {
    const onChecked = (session: IChargingSession, checked: boolean) => {
      setSelected(selected => {
        if (checked) {
          return [...selected, session];
        } else {
          return selected.filter(x => x.id !== session.id);
        }
      });
    };

    return getTableColumns(None, true, selected, onChecked);
  }, [selected]);

  const filteredSessions = useMemo(() => {
    let result = sessions;

    if (email) result = result.filter(x => x.user?.emailAddress === email);
    if (username) result = result.filter(x => x.userName === username);
    if (paymentType) result = result.filter(x => x.paymentType === paymentType);

    return result.map(session => ({...session, emailAddress: session.user?.emailAddress}));
  }, [sessions, email, username, paymentType]);

  const customSettings = useCardColumnSettings(columns);

  const handleChangeOrganization = useCallback(
    (org: IOrganization | undefined) => {
      updateSettings({organizationId: org ? org.id : undefined});
    },
    [updateSettings]
  );

  const handleChangeUserEmail = (email: string) => {
    const selectedUserEmail = username === 'anyUserEmail' ? '' : email;
    setEmail(selectedUserEmail);
  };

  const handleChangeUserUsername = (username: string) => {
    const selectedUsername = username === 'anyUsername' ? '' : username;
    setUsername(selectedUsername);
  };

  const handleChangePaymentType = useCallback(
    (type: string) => {
      const paymentType = type === ChargingStationPaymentType.Any ? undefined : (type as ChargingStationPaymentType);
      updateSettings({paymentType});
    },
    [updateSettings]
  );

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refresh} />
      {state.ready && (
        <ExportCsv
          fields={columns}
          settings={settings.table}
          items={filteredSessions}
          name={T('chargingSessions.title')}
        />
      )}
      <PeriodSelector
        settings={settings.period}
        onChanged={period => updateSettings({period})}
        withoutInterval={true}
      />
      {!isBasicuser && (
        <>
          {inputOrganizations.showInput && (
            <OrganizationInput
              name="organization"
              organizations={inputOrganizations.organizations}
              value={organization}
              onChange={handleChangeOrganization}
              onUpdateQuery={updateOrganizationInputQuery}
            />
          )}
          {hasFeature(AppFeature.SocialLogin) ? (
            <Select name="user-email" value={email || ''} defaultValue="" onValueChange={handleChangeUserEmail}>
              <SelectTrigger className="tw-pl-1 !tw-max-w-[13rem] !tw-text-base">
                <SelectValue placeholder={T('chargingSessions.allUsers')} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="anyUserEmail">{T('chargingSessions.allUsers')}</SelectItem>
                  {emails.map(email => (
                    <SelectItem key={email} value={email}>
                      {email}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          ) : (
            <Select
              name="user-username"
              value={username || ''}
              defaultValue=""
              onValueChange={handleChangeUserUsername}
            >
              <SelectTrigger className="tw-pl-1 !tw-max-w-[11rem] !tw-text-base">
                <SelectValue placeholder={T('chargingSessions.allUsers')} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="anyUserName">{T('chargingSessions.allUsers')}</SelectItem>
                  {usernames.map(username => (
                    <SelectItem key={username} value={username}>
                      {username}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          )}
        </>
      )}
      <Select
        name="payment-type"
        value={settings.paymentType || ''}
        defaultValue={ChargingStationPaymentType.Any}
        onValueChange={handleChangePaymentType}
      >
        <SelectTrigger className="tw-pl-1 !tw-max-w-[11rem] !tw-text-base">
          <SelectValue placeholder={T('paymentMethod.any')} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value={ChargingStationPaymentType.Any}>{T('paymentMethod.any')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.App}>{T('paymentMethod.app')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.Whitelist}>{T('paymentMethod.whitelist')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.RFID}>{T('paymentMethod.rfid')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.SplitBilling}>{T('paymentMethod.splitBilling')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.PaymentTerminal}>
              {T('paymentMethod.paymentTerminal')}
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      {/* <Spring /> */}
      {selected.length > 0 && (
        <>
          <Button
            variant="secondary_default"
            size="lg"
            title={T('shipment.actions.clear')}
            onClick={handleClearSelection}
            data-testid="clear-selection"
            className="!tw-mx-1.5"
          >
            <Clear width={16} height={16} />
          </Button>
          <Button
            variant="secondary_default"
            size="lg"
            title={T('shipment.actions.move')}
            onClick={handleFixSelected}
            data-testid="fix-selection"
            className="!tw-ml-1.5 !tw-mr-3"
          >
            <Hammer width={16} height={16} />
          </Button>
        </>
      )}
    </CardActions>
  );

  return (
    <CardView customSettings={customSettings} actions={actions} {...cardViewProps(props)}>
      <Table
        key={tableKey}
        style={{flexGrow: 1}}
        items={filteredSessions}
        fields={columns}
        rowKey={rowKey}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        noun="chargingSession"
        emptyMessage={T('chargingTransactions.none')}
        selected={selected.length}
      />
    </CardView>
  );
};

const defaultTableSettings: IPersistedTableSettings = {
  pageSize: 20,
  sortColumn: 'from',
  sortOrder: SortOrder.DESCENDING,
  columns: [
    {name: 'from', visible: true},
    {name: 'to', visible: true},
    {name: 'duration', visible: true},
    {name: 'energy', visible: true},
    {name: 'paymentType', visible: true},
    {name: 'cost', visible: true},
    {name: 'rfid', visible: true},
    ...(hasFeature(AppFeature.SocialLogin)
      ? [{name: 'emailAddress', visible: true}]
      : [{name: 'userName', visible: true}])
  ]
};

const CARD: ICardType<IChargingSessionsSettings> = {
  title: 'chargingSessions.title',
  description: 'chargingSessions.description',
  categories: [CardCategory.EV],
  rights: UserRights.User,
  type: CardTypeKey.ChargingSessions,
  cardClass: ChargingSessions,
  width: 4,
  height: 2,
  locationAware: CardLocationAwareness.Unaware,
  defaultSettings: {
    table: defaultTableSettings,
    period: {
      interval: Interval.DAY, // unused
      period: Period.DAYS_7
    }
  }
};
export default CARD;
