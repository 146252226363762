import {CSSProperties, useCallback, useEffect, useMemo, useState} from 'react';

import {useAppContext} from '../../app/context';
import {useUser} from '../../cards/CardUtils';
import {IOrganization} from '../../models/Organization';
import {None} from '../../utils/Arrays';
import {useDebounce} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {FormFeedback} from '../bootstrap';
import {ISelectOption, SearchableSelectInput} from '../SearchableSelectInput';

import FormInputGroup from './FormInputGroup';

export interface OrganizationInputQueryState {
  focused: boolean;
  query: string;
}

export interface OrganizationInputData {
  loading: boolean;
  showInput: boolean;
  organizations: IOrganization[];
  defaultOrganization: IOrganization | undefined;
}

export function useQueryableOrganizations(): [
  OrganizationInputData,
  (state: Partial<OrganizationInputQueryState>) => void
] {
  const [state, setState] = useState<OrganizationInputQueryState>({focused: false, query: ''});
  const {focused, query} = state;
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [loading, setLoading] = useState(false);
  const {api} = useAppContext();
  const user = useUser();
  const isServiceDesk = user.isServiceDesk();
  const userId = user.userId;

  const debouncedSearchTerm = useDebounce(query, 500);

  const refresh = useCallback(
    (refresh?: boolean) => {
      let promise: Promise<IOrganization[]>;
      if (isServiceDesk) {
        if (focused) {
          setLoading(true);
          promise = api.organizations.getOrgsBySearchTerm(debouncedSearchTerm, 1, 10);
        } else {
          setOrganizations(None);
          return;
        }
      } else {
        setLoading(true);
        promise = api.getUserOrganizations(userId, refresh);
      }

      promise.finally(() => setLoading(false)).then(setOrganizations);
    },
    [api, isServiceDesk, userId, focused, debouncedSearchTerm]
  );
  useEffect(refresh, [refresh]);
  const data = {
    loading,
    showInput: user.isServiceDesk() || organizations.length > 1,
    organizations,
    defaultOrganization: user.isServiceDesk() || organizations.length === 0 ? undefined : organizations[0]
  };

  const updateState = useCallback(
    (updates: Partial<OrganizationInputQueryState>) => setState(state => ({...state, ...updates})),
    [setState]
  );

  return [data, updateState];
}

interface OrganizationInputProps {
  organizations: IOrganization[];
  value: IOrganization | undefined;
  onChange: (value: IOrganization | undefined) => void;
  onUpdateQuery: (updates: Partial<OrganizationInputQueryState>) => void;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
  name?: string;
  noneLabel?: string;
  clearable?: boolean;
}

export function OrganizationInput(props: OrganizationInputProps) {
  const {
    organizations,
    value,
    onChange,
    onUpdateQuery,
    placeholder,
    disabled,
    style,
    name,
    noneLabel,
    clearable = false
  } = props;

  const options: ISelectOption[] = useMemo(() => {
    if (organizations.length === 0 && value) {
      const name = value.parentId ? `${value.parentName} / ${value.name}` : value.name;
      return [{value: value.id.toString(), label: name}];
    }
    const result = organizations.map(org => {
      const name = org.parentId ? `${org.parentName} / ${org.name}` : org.name;
      return {value: org.id.toString(), label: name};
    });
    result?.sort((a, b) => a.label.localeCompare(b.label));
    return result;
  }, [organizations, value]);

  const handleOrganizationChanged = useCallback(
    (value: string) => {
      if (value === '') {
        onChange(undefined);
      } else {
        const organizationId = parseInt(value);
        const organization = organizations?.find(organization => organization.id === organizationId);
        if (organization) onChange(organization);
      }
    },
    [organizations, onChange]
  );

  const handleInputChanged = (filter: string) => {
    onUpdateQuery({query: filter});
  };

  const handleFocus = () => {
    onUpdateQuery({focused: true});
  };

  return (
    <SearchableSelectInput
      name={name}
      value={value === undefined ? '' : value.id.toString()}
      onChange={handleOrganizationChanged}
      onInputChange={handleInputChanged}
      onFocus={handleFocus}
      options={options}
      placeholder={placeholder || T('organizationInput.placeholder')}
      disabled={disabled}
      style={style}
      noneLabel={noneLabel}
      clearable={clearable}
    />
  );
}

interface OrganizationInputGroupProps {
  label: string;
  name: string;
  value: IOrganization | undefined;
  organizations: IOrganization[];
  onChange: (organization: IOrganization | undefined) => void;
  onUpdateQuery: (updates: Partial<OrganizationInputQueryState>) => void;
  inputRef?: (element: HTMLInputElement | null) => void;
  disabled?: boolean;
  info?: string;
  placeholder?: string;
  error?: string;
  noneLabel?: string;
}

export const OrganizationInputGroup = (props: OrganizationInputGroupProps) => {
  const {name, label, error, info, noneLabel, ...inputProps} = props;

  return (
    <FormInputGroup name={name} label={label} error={error} info={info}>
      <OrganizationInput name={name} {...inputProps} />
      <FormFeedback>{noneLabel || T('activationCodes.noOrganizations')}</FormFeedback>
    </FormInputGroup>
  );
};
