import React, {useMemo, useCallback} from 'react';
import {FormGroup} from 'reactstrap';

import PeriodSelector, {
  Period,
  PeriodSettings,
  getReferenceLengthOfInterval,
  getPeriodRangeForTimezone,
  PeriodRoundingMode
} from '../../components/PeriodSelector';
import SelectLocationIdButton from '../../components/SelectLocationIdButton';
import Table, {SortOrder, getEffectiveColumns, ISelectedRow} from '../../components/Table';
import {Checkbox} from '../../components/ui/checkbox';
import {SupportedFeature} from '../../models/ActivationCode';
import {UserRights} from '../../models/AuthUser';
import {CardDisplayType, HighOrLowLevel} from '../../models/CardSettings';
import {IConsumptionValue, isForecastValue} from '../../models/ConsumptionValue';
import {Device} from '../../models/Device';
import {DeviceType} from '../../models/DeviceType';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {getHistoricalChannel, getHistoricalLoad} from '../../models/HistoricalChannel';
import {LoadType, NoLoads} from '../../models/Load';
import {isBigConsumer, isLocalityChild, LocationFunctionType} from '../../models/Location';
import {Interval} from '../../models/UsageValue';
import {None} from '../../utils/Arrays';
import {getDualUltraConsumption, getDualUltraHighLevelConfiguration} from '../../utils/DualUltra';
import {
  useHistoricalDeviceInfo,
  useLocationActivationCode,
  useChannels,
  useRetentionPolicy,
  useChildLocations
} from '../../utils/FunctionalData';
import {useRefCallback, useAutoRefresh, useCardLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationDetails, useUser} from '../CardUtils';
import {Reload, ExportCsv} from '../components/actions';
import {CardActions} from '../components/CardActions';

import {CardView, cardViewProps, CustomActions, CustomSettings} from '../components/CardView';

import {ElectricityChartWrapper} from './ElectricityChart';
import {ElectricityChartData} from './ElectricityChartData';
import {getTableColumns, electricityColumnIds} from './ElectricityValuesColumns';
import {ElectricityValuesSettings} from './ElectricityValuesSettings';
import {IElectricityCardSettings} from './Settings';

const ALLOWED_INTERVALS = [
  Interval.MINUTES_5,
  Interval.X_MINUTES_10,
  Interval.X_MINUTES_15,
  Interval.X_MINUTES_20,
  Interval.X_MINUTES_30,
  Interval.HOUR,
  Interval.DAY,
  Interval.WEEK,
  Interval.MONTH,
  Interval.YEAR
];

const DEFAULT_SETTINGS: IElectricityCardSettings = {
  cardType: CardDisplayType.Chart,
  level: HighOrLowLevel.Low,
  period: Period.DAYS_7,
  interval: Interval.HOUR,
  series: [electricityColumnIds.consumption, electricityColumnIds.solar, electricityColumnIds.alwaysOn],
  adjustRangeToActualData: false,
  table: {
    pageSize: 10,
    sortColumn: 'timestamp',
    sortOrder: SortOrder.DESCENDING,
    columns: [
      {name: electricityColumnIds.consumption, visible: true},
      {name: electricityColumnIds.solar, visible: true},
      {name: electricityColumnIds.alwaysOn, visible: true},
      {name: electricityColumnIds.import, visible: true},
      {name: electricityColumnIds.export, visible: true}
    ]
  },
  showMinMax: false,
  disabledGroupSeries: []
};

const rowKey = (item: IConsumptionValue) => item.timestamp;

const ElectricityValues = (props: ICardProps<IElectricityCardSettings>) => {
  const {fetch, settings, updateSettings, loading} = props;
  const {interval, showMinMax, series, disabledGroupSeries} = settings;

  const location = useCardLocationDetails(settings);
  const isChild = location && isLocalityChild(location);
  const [childs] = useChildLocations(fetch, location);
  const isDualUltraAssembly =
    location !== undefined &&
    location.chargingStation?.model === 'ULTRA_DUAL_CABLE' &&
    (location.chargingStation?.serialNumber || '').length < 11;
  const stationSerialNumber = location?.chargingStation?.serialNumber;

  const {type: deviceType = undefined, locationId = undefined} = useHistoricalDeviceInfo(fetch, location) || {};
  const [activationCode] = useLocationActivationCode(fetch, location && location.id);

  const isInfinity = deviceType && Device.isInfinity(deviceType);
  const hasHighLevelConfig = isInfinity || deviceType === DeviceType.P1S1 || location?.chargingStation !== undefined;
  const [highLevelConfiguration, refreshHighLevelConfig] = useCardLoader(
    api => {
      if (locationId === undefined || !hasHighLevelConfig) {
        return Promise.resolve(undefined);
      } else if (isDualUltraAssembly && stationSerialNumber !== undefined) {
        return api.chargingStations
          .getBySerial(stationSerialNumber)
          .then(station => getDualUltraHighLevelConfiguration(api, locationId, station));
      } else {
        return api.getHighLevelConfiguration(locationId);
      }
    },
    [locationId],
    T('phasorDisplay.loading.configuration'),
    undefined
  );
  const channels = useChannels(fetch, locationId, deviceType);
  const phaseType = highLevelConfiguration && highLevelConfiguration.phaseType;
  const loads = (highLevelConfiguration && highLevelConfiguration.measurements) || NoLoads;
  const functionType = location?.functionType || LocationFunctionType.None;

  const me = useUser();

  const level = isInfinity ? settings.level : HighOrLowLevel.Low;
  const hasSolar = location?.hasSolar ?? false;
  const bigConsumer = location ? isBigConsumer(location) : false;
  const timeZoneId = location && location.timeZoneId;

  const newChannels = useMemo(() => {
    if (location === undefined) return None;

    const powerMultiplier = isBigConsumer(location) ? 0.001 : 1;
    return channels.map(channel => getHistoricalChannel(location.id, channel, powerMultiplier));
  }, [channels, location]);
  const newLoads = useMemo(() => {
    if (location === undefined) return None;

    const powerMultiplier = isBigConsumer(location) ? 0.001 : 1;
    return loads.map(load => getHistoricalLoad(location.id, load, powerMultiplier));
  }, [loads, location]);

  const [data, refreshData] = useCardLoader(
    async api => {
      if (locationId === undefined || timeZoneId === undefined) return;

      const retention = await api.getRetentionPolicy(locationId);
      const activePeriod = getPeriodRangeForTimezone(settings, timeZoneId, retention, PeriodRoundingMode.EXCLUSIVE);

      const items =
        isDualUltraAssembly && stationSerialNumber !== undefined
          ? await getDualUltraConsumption(api, locationId, stationSerialNumber, activePeriod)
          : await api.getElectricityConsumption(locationId, activePeriod);
      return {rawConsumption: items, activePeriod};
    },
    [locationId, timeZoneId, settings.from, settings.to, settings.period, settings.interval, stationSerialNumber],
    T('electricityValues.loading.data'),
    undefined
  );
  const {rawConsumption = None, activePeriod} = data || {};
  const [retention] = useRetentionPolicy(fetch, locationId);

  const hasSolarValues = useMemo(
    () => rawConsumption.some(item => item.solar !== undefined && item.solar !== 0),
    [rawConsumption]
  );
  const isSolarForecastEnabled = settings.table.columns.some(c => {
    const c2 = c as ISelectedRow;
    return (
      (c2.name === electricityColumnIds.solarForecast || c2.name === electricityColumnIds.consumptionForecast) &&
      c2.visible
    );
  });
  const consumption = useMemo(
    () => (isSolarForecastEnabled ? rawConsumption : rawConsumption.filter(item => !isForecastValue(item))),
    [rawConsumption, isSolarForecastEnabled]
  );

  const [activeInterval, chartData] = useMemo(() => {
    if (activePeriod === undefined) return [interval, undefined];
    if (consumption.length === 1) {
      // highcharts is confused for its bars if it receives only a single value
      consumption.push({
        serviceLocationId: consumption[0].serviceLocationId,
        timestamp: consumption[0].timestamp + getReferenceLengthOfInterval(interval),
        aggregation: interval,
        delta: consumption[0].delta
      });
    }

    let activeInterval = interval;

    if (consumption.length > 0) {
      const aggregation = consumption[0].aggregation;
      if (aggregation === Interval.INTERVAL) {
        activeInterval = Interval.MINUTES_5;
      } else {
        activeInterval = aggregation;
      }
    }

    return [
      activeInterval,
      new ElectricityChartData(consumption, bigConsumer, activePeriod.from, activePeriod.to, interval)
    ];
  }, [consumption, bigConsumer, activePeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  const isServiceDesk = me.isServiceDesk();
  const hasStorage = loads.some(load => load.type === LoadType.Storage);
  const getColumnsForLevel = useCallback(
    (level: HighOrLowLevel) =>
      getTableColumns(
        functionType,
        deviceType,
        phaseType || PhaseType.Star,
        bigConsumer,
        timeZoneId,
        hasSolar,
        isServiceDesk,
        isDualUltraAssembly,
        level,
        newChannels,
        newLoads,
        showMinMax,
        true,
        hasStorage,
        activeInterval,
        childs
      ),
    [
      functionType,
      deviceType,
      bigConsumer,
      timeZoneId,
      hasSolar,
      hasStorage,
      isServiceDesk,
      isDualUltraAssembly,
      newChannels,
      showMinMax,
      activeInterval,
      phaseType,
      childs,
      newLoads
    ]
  );
  const columns = useMemo(() => getColumnsForLevel(level), [getColumnsForLevel, level]);

  const actualSettings = useMemo(() => {
    // remove series and columns that aren't actually visible
    const selectedColumns = getEffectiveColumns(settings.table, columns);
    const series = settings.series.filter(serie =>
      selectedColumns.some(column => column.visible && column.name === serie)
    );
    if (settings.table.grouped) {
      const hasGroupColumns = selectedColumns.some(column => column.fromGroup || false);
      if (hasGroupColumns) {
        // autoselect all group columns
        const {disabledGroupSeries} = settings;
        const groupColumns = selectedColumns
          .filter(column => column.visible && column.fromGroup && !disabledGroupSeries.includes(column.name))
          .map(column => column.name);
        series.push(...groupColumns);
      }
    }
    const actualTable = {...settings.table, columns: selectedColumns};
    const actual = {...settings, series, table: actualTable, activeInterval};
    return actual;
  }, [settings, activeInterval, columns]);

  const handleChangePeriod = (settings: PeriodSettings) => updateSettings(settings);

  const handleShowSeries = useRefCallback((id: string) => {
    if (disabledGroupSeries.includes(id)) {
      updateSettings({
        disabledGroupSeries: disabledGroupSeries.filter(serie => serie !== id)
      });
    } else {
      updateSettings({series: [...series, id]});
    }
  });

  const handleHideSeries = useRefCallback((id: string) => {
    const index = series.indexOf(id);
    if (index < 0) {
      const allSeries = actualSettings.series;
      if (allSeries.includes(id)) {
        updateSettings({disabledGroupSeries: [...disabledGroupSeries, id]});
      }
    } else {
      const newSeries = [...series];
      newSeries.splice(index, 1);
      updateSettings({series: newSeries});
    }
  });

  const handleMinMaxChanged = (checked: boolean) => {
    updateSettings({showMinMax: checked});
  };

  const hasMinMax =
    activationCode !== undefined &&
    activationCode.supportedFeatures !== undefined &&
    (activationCode.supportedFeatures.includes(SupportedFeature.DataAnalysis) ||
      activationCode.supportedFeatures.includes(SupportedFeature.MinMaxCurrent) ||
      activationCode.supportedFeatures.includes(SupportedFeature.MinMaxVoltage));

  const handleRefresh = () => {
    refreshHighLevelConfig();
    refreshData();
  };
  useAutoRefresh(refreshData);

  const actions: CustomActions = state => (
    <CardActions>
      <PeriodSelector
        settings={settings}
        onChanged={handleChangePeriod}
        allowedIntervals={ALLOWED_INTERVALS}
        retention={retention}
      />
      <Reload onReload={handleRefresh} />
      {state.ready && (
        <ExportCsv
          name={state.title}
          range={settings}
          location={location}
          fields={columns}
          items={consumption}
          settings={settings.table}
        />
      )}
      {hasMinMax && (
        <FormGroup style={{position: 'relative', top: 10, left: 20}}>
          <Checkbox
            id="level"
            name="level"
            label={T('electricityValues.showMinMax')}
            defaultChecked={showMinMax}
            onCheckedChange={handleMinMaxChanged}
            testId="level"
          />
        </FormGroup>
      )}
    </CardActions>
  );

  let error;
  if (isChild && !me.isServiceDesk()) {
    error = (
      <>
        {T('electricityValues.error.notSupportedForLocalityChild')}
        <br />
        <SelectLocationIdButton fetch={fetch} locationId={location!.parentId!} />
      </>
    );
  } else if (!loading) {
    if (deviceType === undefined && !location?.hasChargingStations) {
      error = T('electricityValues.error.notInstalled');
    } else if (consumption.length === 0) error = T('generic.noData');
  }

  const renderSettings: CustomSettings<IElectricityCardSettings> = (editingSettings, updateEditingSettings) => {
    if (!location) return <div />;

    const isInfinity = deviceType && Device.isInfinity(deviceType);
    const isServiceDesk = me.isServiceDesk();
    const isPro = deviceType && Device.isPro(deviceType);
    const canHaveSolar = hasSolar || isPro || isInfinity || isServiceDesk || hasSolarValues;

    return (
      <ElectricityValuesSettings
        fetch={fetch}
        me={me}
        location={location}
        editingSettings={editingSettings}
        canHaveSolar={canHaveSolar}
        channels={newChannels}
        loads={newLoads}
        phaseType={phaseType || PhaseType.Star}
        updateEditingSettings={updateEditingSettings}
        hasSolarForecast={true}
        hasStorage={hasStorage}
      />
    );
  };

  const renderTable = () => {
    return (
      <Table
        items={consumption}
        fields={columns}
        rowKey={rowKey}
        noun="electricityValue"
        settings={actualSettings.table}
        hasPaging={true}
        updateSettings={table => updateSettings({table})}
      />
    );
  };

  const renderChart = () =>
    location &&
    chartData &&
    !chartData.isEmpty() &&
    activePeriod && (
      <ElectricityChartWrapper
        hasSolar={location.hasSolar || false}
        bigConsumer={bigConsumer}
        deviceType={deviceType || DeviceType.UNKNOWN}
        period={activePeriod}
        settings={actualSettings}
        fields={columns}
        data={chartData}
        channels={newChannels}
        loads={newLoads}
        hasSolarValues={hasSolarValues}
        showMinMax={showMinMax}
        onShowSeries={handleShowSeries}
        onHideSeries={handleHideSeries}
        phaseType={phaseType || PhaseType.Star}
        adjustRangeToActualData={settings.adjustRangeToActualData}
        isDualUltraAssembly={isDualUltraAssembly}
        childs={childs}
      />
    );

  const getContent = () => {
    const {cardType} = settings;
    switch (cardType) {
      case CardDisplayType.Chart:
        return renderChart();
      case CardDisplayType.Table:
        return renderTable();
      default:
        return null;
    }
  };

  return (
    <CardView actions={actions} error={error} customSettings={renderSettings} {...cardViewProps(props)}>
      {getContent()}
    </CardView>
  );
};

const CARD: ICardType<IElectricityCardSettings> = {
  type: CardTypeKey.ElectricityValues,
  title: 'electricityValues.title',
  description: 'electricityValues.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegularOrServiceDesk,
  cardClass: ElectricityValues
};
export default CARD;
