import React, {useMemo, useCallback} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import Table, {SortOrder} from '../../components/Table';
import {Button} from '../../components/ui/button';
import Plus from '../../components/ui-lib/icons/small/Plus';
import {useModals} from '../../modals/ModalContext';
import {IActivationCode} from '../../models/ActivationCode';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IOrganization} from '../../models/Organization';
import {SuccessResponse, GeneralFailure} from '../../models/Response';
import {useAllOrganizationActivationCodes, useOrganization} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardColumnSettings, useUser} from '../CardUtils';
import Reload from '../components/actions/Reload';

import {CardActions} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {rowKey, getColumns} from './Columns';
import EditActivationCode from './EditActivationCode';

import {IRemoveActivationCodeResult, RemoveActivationCodeModal} from './RemoveActivationCodeModal';
import {SelectOrganizationModal} from './SelectOrganizationModal';

interface IActivationCodesSettings extends ICardSettingsWithTable {
  organizationId: number | null;
}

const ActivationCodes = (props: ICardProps<IActivationCodesSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const {api} = useAppContext();
  const modals = useModals();
  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = settings.organizationId || inputOrganizations.defaultOrganization?.id;
  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, organizationId);
  const me = useUser();
  const isServiceDesk = me.isServiceDesk();

  const [activationCodes, refreshActivationCodes] = useAllOrganizationActivationCodes(fetch, organizationId);

  const tableColumns = useMemo(() => {
    const handleRemoval = async (code: IActivationCode) => {
      const result = await modals.show<IRemoveActivationCodeResult>(props => (
        <RemoveActivationCodeModal code={code} {...props} />
      ));

      if (!result.confirmed) return;

      api.activationCodes
        .delete(code.code, result.withHistory)
        .catch(() => ({success: false}) as SuccessResponse)
        .then(response => {
          if (response && response.success) {
            refreshActivationCodes();
            NotificationManager.success(T('activationCodes.remove.success'));
          } else if (response && response.generalFailure === GeneralFailure.ActivationCodeIsStillInUse) {
            NotificationManager.error(T('activationCodes.remove.failedInUse'));
          } else {
            NotificationManager.error(T('activationCodes.remove.failed'));
          }
        });
    };

    const handleEdit = (code: IActivationCode) => {
      modals
        .show(props => <EditActivationCode organization={organization} editing={code} {...props} />)
        .then(changed => changed && refreshActivationCodes());
    };

    const handleCreateChild = async (code: IActivationCode) => {
      const suborganizations = await api.organizations.getSuborganizations(code.organization.id);
      const selectedSuborganization = await modals.show<IOrganization | undefined>(props => (
        <SelectOrganizationModal
          organizations={suborganizations}
          title={T('activationCodes.createChild.title')}
          {...props}
        />
      ));
      if (selectedSuborganization) {
        await api.activationCodes.createChild(code.code, selectedSuborganization.id);
        refreshActivationCodes();
      }
    };

    const handleSetDefault = (code: IActivationCode) => {
      if (code.isDefault) return;

      api.activationCodes.update(code.code, {isDefault: true}).then(refreshActivationCodes);
    };

    return getColumns(
      isServiceDesk,
      {
        onClickedEdit: handleEdit,
        onClickedRemove: handleRemoval,
        onClickedCreateChild: handleCreateChild,
        onClickedSetDefault: handleSetDefault
      },
      organization
    );
  }, [api, modals, isServiceDesk, organization, refreshActivationCodes]);

  const handleClickedCreate = () => {
    modals
      .show(props => (
        <EditActivationCode organization={organization} isDefault={activationCodes.length === 0} {...props} />
      ))
      .then(changed => changed && refreshActivationCodes());
  };

  const organizationInput = (
    <OrganizationInput
      organizations={inputOrganizations.organizations}
      value={organization}
      onChange={useCallback(org => updateSettings({organizationId: org ? org.id : null}), [updateSettings])}
      onUpdateQuery={updateOrganizationInputQuery}
      placeholder=""
      name="organization"
    />
  );

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refreshActivationCodes} />
      {state.ready && organizationInput}
      {/* <Spring /> */}
      {state.ready && isServiceDesk && (
        <Button variant="secondary_default" size="lg" title={T('activationCodes.create')} onClick={handleClickedCreate}>
          <Plus width={16} height={16} className="tw-mr-2" />
          {T('activationCodes.create')}
        </Button>
      )}
    </CardActions>
  );

  const customSettings = useCardColumnSettings(tableColumns);
  let emptyMessage = T('activationCodes.noneAvailable');
  if (organizationId === undefined) {
    emptyMessage = T('card.error.noOrganizationSelected');
  } else if (organization === undefined) {
    emptyMessage = T('card.error.organizationNotFound');
  }

  return (
    <CardView actions={actions} customSettings={customSettings} {...cardViewProps(props)}>
      <Table
        fields={tableColumns}
        items={activationCodes as IActivationCode[]}
        rowKey={rowKey}
        hasPaging={true}
        noun="activationCode"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        emptyMessage={emptyMessage}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: IActivationCodesSettings = {
  table: {
    pageSize: 10,
    sortColumn: 'name',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'name', visible: true},
      {name: 'code', visible: true},
      {name: 'organization', visible: true},
      {name: 'isDefault', visible: true},
      {name: 'features', visible: true},
      {name: 'voltageHarmonics', visible: true},
      {name: 'currentHarmonics', visible: true},
      {name: 'contractYears', visible: true}
    ]
  },
  organizationId: null
};
const CARD: ICardType<IActivationCodesSettings> = {
  type: CardTypeKey.ActivationCodes,
  title: 'activationCodes.title',
  description: 'activationCodes.description',
  categories: [CardCategory.CONFIGURATION],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: ActivationCodes
};
export default CARD;
