import {RowActionButton} from '../../components/bootstrap/RowActions';
import RowActions from '../../components/Table/RowActions';
import {InvitationStatusField} from '../../models/InvitableEntry';
import {RFIDCard} from '../../models/RFIDCard';
import {
  CalculatedStringField,
  ComponentField,
  DEFAULT_OPTIONS,
  IFieldOptions,
  ITableField,
  StringField
} from '../../models/Table';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {T} from '../../utils/Internationalization';

import {ChargingCardEntry} from './model';

interface Actions {
  onClickedEdit: (item: RFIDCard) => void;
  onClickedRemove: (item: RFIDCard) => void;
  onClickedDeleteInvitation: (id: string) => void;
}

export function getColumns(readOnly: boolean, actions: Actions): ITableField<ChargingCardEntry>[] {
  const columns: ITableField<ChargingCardEntry>[] = [
    new StringField('value', T('publicChargingTokens.field.value')),
    new StringField('name', T('publicChargingTokens.field.name'))
  ];

  if (!hasFeature(AppFeature.HideUsernames)) {
    columns.push(new StringField('username', T('publicChargingTokens.field.user')));
  }
  if (hasFeature(AppFeature.SocialLogin)) {
    columns.push(new StringField('userFirstName', T('profile.firstName')));
    columns.push(new StringField('userLastName', T('profile.lastName')));
  }
  columns.push(new StringField('employeeNumber', T('publicChargingTokens.field.employeeNumber')));
  columns.push(new InvitationStatusField('confirmed', T('publicChargingTokens.field.confirmed')));

  columns.push(
    new ComponentField('actions', T('generic.actions'), (item: ChargingCardEntry) => (
      <RowActions>
        {item.card && item.card.id.startsWith('RF-') && !readOnly && (
          <>
            <RowActionButton
              icon="Pencil"
              title={T('publicChargingTokens.action.edit')}
              onClick={() => actions.onClickedEdit(item.card!)}
            />
            <RowActionButton
              action="delete"
              title={T('publicChargingTokens.action.remove')}
              onClick={() => actions.onClickedRemove(item.card!)}
            />
          </>
        )}
        {item.card === undefined && (
          <RowActionButton
            action="delete"
            title={T('publicChargingTokens.action.remove')}
            onClick={() => actions.onClickedDeleteInvitation(item.id.substring(1))}
          />
        )}
      </RowActions>
    ))
  );

  return columns;
}
