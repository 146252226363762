import {Col, Row} from '../../../components/bootstrap';
import {NumberInputGroup, NumberValue} from '../../../components/inputs/NumberInput';
import {TextInputGroup} from '../../../components/inputs/TextInput';
import {Checkbox} from '../../../components/ui/checkbox';
import {FormState} from '../../../utils/FormState';
import {T} from '../../../utils/Internationalization';

import {useUser} from '../../CardUtils';

import {EditOrganizationFormState} from './EditOrganizationModels';

interface EditOrganizationPublicChargingTabProps {
  formState: FormState;
  state: EditOrganizationFormState;
  updateState: (updates: Partial<EditOrganizationFormState>) => void;
}

export default function EditOrganizationPublicChargingTab(props: EditOrganizationPublicChargingTabProps) {
  const {formState, state, updateState} = props;
  const me = useUser();

  return (
    <div style={{marginTop: '0.5rem'}}>
      <Row>
        <Col xl={4}>
          <TextInputGroup
            name="emspBillReference"
            label={T('organizations.tab.publicCharging.billReference')}
            info={T('organizations.tab.publicCharging.billReference.info')}
            value={state.emspBillReference}
            onChange={emspBillReference => updateState({emspBillReference})}
          />
          {me.isServiceDesk() && (
            <>
              <Checkbox
                id="emspSpecificFee"
                name="emspSpecificFee"
                label={T('organizations.tab.publicCharging.specificFee')}
                checked={state.emspSessionFee !== undefined}
                onCheckedChange={checked => updateState({emspSessionFee: checked ? NumberValue.none() : undefined})}
                testId="emspSpecificFee"
              />
              {state.emspSessionFee && (
                <NumberInputGroup
                  name="emspSessionFee"
                  label={T('organizations.tab.publicCharging.specificFee.label')}
                  info={T('organizations.tab.publicCharging.specificFee.info')}
                  min={0}
                  value={state.emspSessionFee}
                  onChange={emspSessionFee => updateState({emspSessionFee})}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
