import * as React from 'react';

import {useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {isAPIResponse} from '../../api/APIClient';
import {useAppContext} from '../../app/context';
import {Alert, Form, SingleActionModal} from '../../components/bootstrap';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {PaperCard} from '../../components/PaperCard';
import {PricingPolicySelector} from '../../components/pricingPolicies/connectPricingPolicy/ConnectPricingPolicy';
import {Button} from '../../components/ui/button';
import {Checkbox} from '../../components/ui/checkbox';
import {InfoCircle, SmappeeLogoMark} from '../../components/ui-lib/icons/medium';
import {Clear, Link} from '../../components/ui-lib/icons/small';
import {useModals} from '../../modals/ModalContext';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ChargingStationOperator, ChargingStationPaymentType} from '../../models/ChargingStation';
import {Contract} from '../../models/Contract';
import {ChargingStationAddress, ILocation} from '../../models/Location';
import {IOrganization} from '../../models/Organization';
import {IPricingPolicy} from '../../models/PricingPolicy';
import {None} from '../../utils/Arrays';
import {BrandColors} from '../../utils/BrandColors';
import {ServerErrorCode, translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';
import {useUser} from '../CardUtils';
import ActivateCPOModal from '../Services/ActivateCPOModal';

import styles from './ChargingStationConfiguration.module.scss';

const SMAPPEE_CSMS = 'SMAPPEE_CSMS';

export interface ChargingStationPaymentTypeSettings {
  paymentTypes: ChargingStationPaymentType[];
  visible: boolean;
  restrictedAccess?: boolean;
  thirdParty?: boolean;
  csms?: {
    host?: string;
    port?: number;
    url?: string;
    deviceActivated?: boolean;
  };
  pricingPolicyId?: IPricingPolicy['id'];

  // legacy
  tariffs?: {
    energy?: number;
    fixed?: number;
    time?: number;
    currency?: string;
    vatRate?: number;
    vatRatePercent?: number;

    roaming?: {
      fixed: number;
      energy: number;
      time: number;
    };
  };
}

interface EditPaymentTypesModalProps extends IPromiseModalProps<ChargingStationPaymentTypeSettings | undefined> {
  stationName: string;
  hasWhitelistedTokens?: boolean;
  hasSplitBillingAgreements?: boolean;
  settings: ChargingStationPaymentTypeSettings;
  operator: ChargingStationOperator | undefined;
  parentId?: number;
  address: ChargingStationAddress | undefined;
  chargingSettings?: ILocation['chargingSettings'];
  save: (settings: ChargingStationPaymentTypeSettings) => Promise<unknown>;
}

function validateCSMSUrl(value: string, label: string, paymentPackageAvailable: boolean) {
  const checkedValue = value.trim();
  if (checkedValue.length === 0) return T('validator.required', {name: label});
  if (!paymentPackageAvailable && value === SMAPPEE_CSMS) {
    return T('chargingStationConfiguration.paymentMethod.paymentPackageRequired');
  }

  return undefined;
}

function RoamingAlert({
  onError,
  organization
}: {
  onError: (error: string | undefined) => void;
  organization?: IOrganization;
}) {
  const modals = useModals();

  const hasContracts = (organization?.contracts || []).length > 0;

  const handleClickedRegister = React.useCallback(() => {
    onError(undefined);

    if (organization) return window.open(`/register-charging-host?organizationId=${organization.id}`);
    window.open(`/register-charging-host`);
  }, [onError, organization]);

  const handleSetupCPO = React.useCallback(() => {
    if (!organization) return;

    modals
      .show<boolean>(props => (
        <ActivateCPOModal
          organizationId={organization.id}
          instantAvailable={!!organization.contracts?.some(c => c === Contract.SEPA) && !!organization.linkedToOdoo}
          {...props}
        />
      ))
      .then(updated => {
        if (updated) NotificationManager.success(T('services.activate.cpo.success'));
      });
  }, [modals, organization]);

  return (
    <>
      <Alert color="warning" style={{marginTop: '1em'}}>
        {T('chargingStationConfiguration.paymentMethod.paymentPackageRequiredForCollection')}
      </Alert>

      {!hasContracts ? (
        <Button onClick={handleClickedRegister}>{T('chargingStationConfiguration.paymentMethod.register')}</Button>
      ) : (
        <Button onClick={handleSetupCPO}>{T('services.setup')}</Button>
      )}
    </>
  );
}

enum PublicChargingMode {
  Disabled,
  Smappee,
  External
}

function getPublicChargingMode(settings: ChargingStationPaymentTypeSettings): PublicChargingMode {
  if (settings.paymentTypes.includes(ChargingStationPaymentType.App)) {
    return PublicChargingMode.Smappee;
  }
  if (settings.paymentTypes.includes(ChargingStationPaymentType.RFID)) {
    if (settings.csms?.url === SMAPPEE_CSMS) {
      return PublicChargingMode.Smappee;
    } else {
      return PublicChargingMode.External;
    }
  } else {
    return PublicChargingMode.Disabled;
  }
}

function EditPaymentTypesModal(props: EditPaymentTypesModalProps) {
  const {stationName, settings, operator, chargingSettings, save} = props;
  const me = useUser();
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);
  const {paymentTypes} = settings;
  const [userOrganizations, setUserOrganizations] = React.useState<IOrganization[]>(None);
  const [error, setError] = React.useState<string>();
  const [csmsUrlError, setCSMSUrlError] = React.useState<string>();
  const activating =
    settings.paymentTypes.includes(ChargingStationPaymentType.RFID) &&
    !!settings.csms &&
    settings.csms.url === SMAPPEE_CSMS &&
    !settings.csms.deviceActivated;

  const organization = React.useMemo(() => {
    return me.isPartnerAdmin() && userOrganizations.length > 0 ? userOrganizations[0] : undefined;
  }, [me, userOrganizations]);

  const smappeeAvailable = operator?.supportsSmappee || false;
  const roamingAvailable = operator?.supportsRoaming || false;

  // form values
  const [authorizedCharging, setAuthorizedCharging] = React.useState(
    !paymentTypes.includes(ChargingStationPaymentType.Free)
  );
  const [publicChargingMode, setPublicChargingMode] = useState(getPublicChargingMode(settings));
  const [csmsUrl, setCSMSUrl] = React.useState<string>(
    settings.csms && settings.csms.url !== 'SMAPPEE_CSMS' ? settings.csms.url || settings.csms.host || '' : ''
  );
  const [publiclyVisible, setPubliclyVisible] = React.useState(settings.visible);
  const [restrictedAccess, setRestrictedAccess] = React.useState<boolean>(
    (settings.visible && settings.restrictedAccess) ?? false
  );
  const [pricingPolicy, setPricingPolicy] = React.useState<IPricingPolicy | undefined>(chargingSettings?.pricingPolicy);

  // event handlers
  const handleClose = React.useCallback(() => resolve(undefined), [resolve]);

  const handleClickedSave = React.useCallback(async () => {
    setCSMSUrlError(undefined);

    const result: ChargingStationPaymentTypeSettings = {
      paymentTypes: [],
      visible: publiclyVisible,
      restrictedAccess: publiclyVisible && restrictedAccess
    };

    if (!authorizedCharging) result.paymentTypes.push(ChargingStationPaymentType.Free);

    if (authorizedCharging) {
      const hasSplitBilling =
        props.hasSplitBillingAgreements || paymentTypes.includes(ChargingStationPaymentType.SplitBilling);
      const hasWhitelist = props.hasWhitelistedTokens || paymentTypes.includes(ChargingStationPaymentType.Whitelist);

      if (hasSplitBilling) {
        result.paymentTypes.push(ChargingStationPaymentType.SplitBilling);
      }

      if (hasWhitelist) {
        result.paymentTypes.push(ChargingStationPaymentType.Whitelist);
      }
      if (
        publicChargingMode === PublicChargingMode.Disabled &&
        !result.paymentTypes.includes(ChargingStationPaymentType.Whitelist) &&
        !hasSplitBilling
      ) {
        result.paymentTypes.push(ChargingStationPaymentType.Whitelist);
      }
      if (publicChargingMode === PublicChargingMode.Smappee) {
        if (!operator || (!operator.supportsSmappee && !operator.supportsRoaming)) {
          setError(T('chargingStationConfiguration.paymentMethod.paymentPackageRequired'));
          return;
        }

        result.pricingPolicyId = pricingPolicy?.id;
        if (operator.supportsSmappee) {
          result.paymentTypes.push(ChargingStationPaymentType.App);
        }
        if (operator.supportsRoaming) {
          if (pricingPolicy === undefined) {
            setError(T('chargingStationConfiguration.property.pleaseSelectPricingPolicy'));
            return;
          }
          result.paymentTypes.push(ChargingStationPaymentType.RFID);
          result.csms = {
            url: 'SMAPPEE_CSMS'
          };
        }
      }
      if (publicChargingMode === PublicChargingMode.External) {
        result.paymentTypes.push(ChargingStationPaymentType.RFID);
        result.csms = {
          url: csmsUrl.endsWith('/') ? csmsUrl.substring(0, csmsUrl.length - 1) : csmsUrl
        };
      }
    }

    return save(result)
      .then(() => resolve(result))
      .catch(err => {
        if (
          isAPIResponse(err) &&
          (err.code === ServerErrorCode.InvalidOCPPUrlFormat ||
            err.code === ServerErrorCode.InvalidOCPPUrlHost ||
            err.code === ServerErrorCode.InvalidOCPPUrlProtocol ||
            err.code === ServerErrorCode.InvalidOCPPUrlInsecure)
        ) {
          return setCSMSUrlError(translateError(err));
        } else {
          return setError(translateError(err, T('chargingStationConfiguration.propertyUpdateFailed')));
        }
      });
  }, [
    authorizedCharging,
    csmsUrl,
    publicChargingMode,
    operator,
    paymentTypes,
    pricingPolicy,
    props.hasSplitBillingAgreements,
    props.hasWhitelistedTokens,
    publiclyVisible,
    resolve,
    restrictedAccess,
    save
  ]);

  // data fetching
  React.useEffect(() => {
    api.getUserOrganizations(me.userId).then(setUserOrganizations);
  }, [api, me.userId]);

  const hasSplitBilling =
    paymentTypes.includes(ChargingStationPaymentType.SplitBilling) || props.hasSplitBillingAgreements;
  const hasWhitelist = paymentTypes.includes(ChargingStationPaymentType.Whitelist) || props.hasWhitelistedTokens;
  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleClose}
      size="lg"
      action={handleClickedSave}
      title={T('chargingStationConfiguration.paymentMethod.title', {
        name: stationName
      })}
    >
      <Form>
        <div className="tw-inline-flex tw-mt-2 tw-gap-4">
          {!settings.thirdParty && (
            <Button
              variant="secondary_default"
              size="lg"
              onClick={() => setAuthorizedCharging(false)}
              active={!authorizedCharging}
            >
              {T('chargingStationConfiguration.paymentMethod.free')}
            </Button>
          )}
          <Button
            variant="secondary_default"
            size="lg"
            onClick={() => setAuthorizedCharging(true)}
            active={authorizedCharging}
          >
            {T('paymentMethod.authorized')}
          </Button>
        </div>

        {authorizedCharging ? (
          <div>
            <p className="mt-2">{T('paymentMethod.restricted')}</p>
            <h2 className={styles.paymentTypeHeader}>{T('chargingStationConfiguration.paymentMethod.rfid.free')}</h2>
            {T('chargingStationConfiguration.paymentMethod.rfid.free.info')}
            <PaperCard className="mb-4 mt-2">
              {hasWhitelist ? (
                <>
                  <i className="fas fa-check-circle" style={{color: BrandColors.SmappeeGreen}} />{' '}
                  {T('paymentMethod.configured.whitelist')}
                </>
              ) : (
                <>
                  <i className="far fa-ban" /> {T('paymentmethod.disabled.whitelist')}
                </>
              )}
            </PaperCard>
            <h2 className={styles.paymentTypeHeader}>
              {T('chargingStationConfiguration.paymentMethod.rfid.splitBilling')}
            </h2>
            {T('chargingStationConfiguration.paymentMethod.rfid.splitBilling.info')}
            <PaperCard className="mb-4 mt-2">
              {hasSplitBilling ? (
                <>
                  <i className="fas fa-check-circle" style={{color: BrandColors.SmappeeGreen}} />{' '}
                  {T('paymentMethod.configured.splitBilling')}
                </>
              ) : (
                <>
                  <i className="far fa-ban" /> {T('paymentmethod.disabled.splitBilling')}
                </>
              )}
            </PaperCard>
            <h2 className={styles.paymentTypeHeader}>{T('chargingStationConfiguration.paymentMethod.rfid.public')}</h2>
            {T('paymentMethod.publicCharging.description')}
            <PaperCard className="mt-2">
              <div className="tw-inline-flex tw-gap-2">
                <Button
                  variant="secondary_default"
                  size="lg"
                  onClick={() => setPublicChargingMode(PublicChargingMode.Disabled)}
                  active={publicChargingMode === PublicChargingMode.Disabled}
                >
                  <span className="tw-mr-2">
                    <Clear width={16} height={16} />
                  </span>
                  <span>{T('paymentMethod.publicCharging.disabled')}</span>
                </Button>
                <Button
                  variant="secondary_default"
                  size="lg"
                  onClick={() => setPublicChargingMode(PublicChargingMode.Smappee)}
                  active={publicChargingMode === PublicChargingMode.Smappee}
                >
                  <span className="tw-mr-2">
                    <SmappeeLogoMark width={16} height={16} />
                  </span>
                  <span>{T('paymentMethod.publicCharging.smappee')}</span>
                </Button>
                {!settings.thirdParty && (
                  <Button
                    variant="secondary_default"
                    size="lg"
                    onClick={() => setPublicChargingMode(PublicChargingMode.External)}
                    active={publicChargingMode === PublicChargingMode.External}
                  >
                    <span className="tw-mr-2">
                      <Link width={16} height={16} />
                    </span>
                    {T('paymentMethod.publicCharging.external')}
                  </Button>
                )}
              </div>
              <div className="mt-2">
                {publicChargingMode === PublicChargingMode.Disabled &&
                  T('paymentMethod.publicCharging.disabled.description')}
                {publicChargingMode === PublicChargingMode.Smappee && (
                  <>
                    {T('paymentMethod.publicCharging.smappee.description')}
                    {(me.isPartnerAdmin() || me.isServiceDesk()) && smappeeAvailable && !roamingAvailable && (
                      <Alert color="warning" style={{marginTop: '1rem'}}>
                        {T('chargingStationConfiguration.paymentMethod.foreignCPO')}
                      </Alert>
                    )}
                    {operator && (
                      <div className="mt-2 mb-2" style={{display: 'flex'}}>
                        <PricingPolicySelector
                          organization={{
                            id: operator?.id,
                            name: operator?.name || '',
                            currency: operator?.currency?.code
                          }}
                          pricingPolicy={pricingPolicy}
                          onSelect={setPricingPolicy}
                        />
                      </div>
                    )}
                    {!roamingAvailable && !smappeeAvailable && (
                      <RoamingAlert onError={setError} organization={organization} />
                    )}
                    <Checkbox
                      id="publicly_visible"
                      name="publicly_visible"
                      label={T('chargingStationConfiguration.publiclyVisible')}
                      info={
                        <div className="tw-flex tw-items-center">
                          <span className="tw-mr-1">
                            <InfoCircle width="16" height="16" />
                          </span>
                          {T('chargingStationConfiguration.publiclyVisible.description')}
                        </div>
                      }
                      checked={publiclyVisible}
                      onCheckedChange={setPubliclyVisible}
                      wrapperClassName="tw-mt-4 tw-mb-2"
                      testId="publicly_visible"
                    />
                    {publiclyVisible && (
                      <Checkbox
                        id="restrictedAccess"
                        name="restrictedAccess"
                        label={T('chargingStationConfiguration.property.restrictedAccess')}
                        info={T('chargingStationConfiguration.property.restrictedAccess.info')}
                        checked={restrictedAccess}
                        onCheckedChange={setRestrictedAccess}
                        wrapperClassName="tw-mb-2"
                        testId="restrictedAccess"
                      />
                    )}
                  </>
                )}
                {activating && (
                  <Alert color="warning" style={{marginTop: '1em', marginLeft: '1em'}}>
                    {T('csms.smappee.activating')}
                  </Alert>
                )}
                {publicChargingMode === PublicChargingMode.External && (
                  <div>
                    {T('paymentMethod.publicCharging.external.description')}
                    <TextInputGroup
                      label=" "
                      name="url"
                      placeholder="ws(s)://"
                      value={csmsUrl}
                      validate={(value, label) => validateCSMSUrl(value, label, operator?.supportsRoaming || false)}
                      onChange={setCSMSUrl}
                      error={csmsUrlError}
                    />
                  </div>
                )}
              </div>
            </PaperCard>
            {error && (
              <Alert color="danger" style={{marginTop: '1rem', marginBottom: 0}}>
                {error}
              </Alert>
            )}
          </div>
        ) : (
          <div>
            <p className="mt-2">{T('chargingStationConfiguration.paymentMethod.free.info')}</p>

            {hasSplitBilling || hasWhitelist ? (
              <Alert color="danger">
                {hasSplitBilling && hasWhitelist
                  ? T('paymentMethod.plugAndChargeWarning.both')
                  : hasSplitBilling
                    ? T('paymentMethod.plugAndChargeWarning.splitBilling')
                    : T('paymentMethod.plugAndChargeWarning.whitelist')}
              </Alert>
            ) : null}
          </div>
        )}
      </Form>
    </SingleActionModal>
  );
}

export {EditPaymentTypesModal};
