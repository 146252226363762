import React, {useCallback, useMemo, useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Modal, ModalBody, ModalFooter, ModalHeader} from '../../components/bootstrap';
import FormSaveButton from '../../components/inputs/FormSaveButton';
import {NumberInputGroup, NumberValue} from '../../components/inputs/NumberInput';
import {OrganizationInputGroup, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {Checkbox} from '../../components/ui/checkbox';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {
  SupportedType,
  SupportedFeature,
  SupportedFeatures,
  IActivationCode,
  getFeatureDisplayName,
  ICreateActivationCodeRequest
} from '../../models/ActivationCode';
import {IOrganization} from '../../models/Organization';
import {FormProvider} from '../../utils/FormContext';
import {T} from '../../utils/Internationalization';
import {useObjectState} from '../../utils/ObjectState';
import {classes} from '../../utils/Styles';
import {validateRequired} from '../../utils/Validation';

import styles from './EditActivationCode.module.scss';

interface FeatureSelectorProps {
  feature: SupportedFeature;
  features: SupportedFeature[];
  onChange: (features: SupportedFeature[]) => void;
}
function FeatureSelector(props: FeatureSelectorProps) {
  const {feature, features, onChange} = props;

  const handleChanged = (checked: boolean) => {
    if (checked) {
      onChange([...features, feature]);
    } else {
      onChange(features.filter(f => f !== feature));
    }
  };

  return (
    <Checkbox
      id={`feature_${feature.toLocaleLowerCase()}`}
      name={`feature_${feature.toLocaleLowerCase()}`}
      label={getFeatureDisplayName(feature)}
      checked={features.includes(feature)}
      onCheckedChange={handleChanged}
      testId={`feature_${feature.toLocaleLowerCase()}`}
    />
  );
}

interface HarmonicsSelectorProps {
  enabled: boolean;
  selected: number[];
  title: string;
  onEnabledChanged: (enabled: boolean) => void;
  onSelectedChanged: (selected: number[]) => void;
}
export const HARMONICS = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 17, 19];

const HarmonicsSelectorRow = (props: HarmonicsSelectorProps) => {
  const {enabled, selected, title, onEnabledChanged, onSelectedChanged} = props;

  const handleEnableCheckedChanged = (enabled: boolean) => {
    onEnabledChanged(enabled);
  };

  const handleCheckedChanged = (harmonic: number, e: string | boolean) => {
    if (e) {
      const newSelected = [...selected, harmonic];
      onSelectedChanged(newSelected);
    } else {
      const newSelected = selected.filter(h => h !== harmonic);
      onSelectedChanged(newSelected);
    }
  };

  return (
    <tr>
      <td>{title}</td>
      <td>
        <Checkbox
          id="enabled"
          name="enabled"
          checked={enabled}
          onCheckedChange={handleEnableCheckedChanged}
          className="!tw-mb-0 !tw-p-0"
          testId="enabled"
        />
      </td>
      {HARMONICS.map(harmonic => (
        <td key={harmonic}>
          <Checkbox
            id={`harmonic-${harmonic}`}
            name={`harmonic-${harmonic}`}
            checked={selected.includes(harmonic)}
            disabled={!enabled}
            onCheckedChange={e => handleCheckedChanged(harmonic, e)}
            className="!tw-mb-0 !tw-p-0"
            testId={`harmonic-${harmonic}`}
          />
        </td>
      ))}
    </tr>
  );
};

interface EditActivationCodeProps extends IPromiseModalProps<boolean> {
  organization?: IOrganization;
  editing?: IActivationCode;
  isDefault?: boolean;
}

interface FormState {
  name: string;
  contractYears: NumberValue;
  isDefault: boolean;
}

const defaultFormState: FormState = {
  name: '',
  contractYears: NumberValue.create(1),
  isDefault: false
};

function getFormStateForCode(code: IActivationCode): FormState {
  return {
    name: code.name || '',
    contractYears: NumberValue.create(code.contractYears),
    isDefault: code.isDefault || false
  };
}

export default function EditActivationCode(props: EditActivationCodeProps) {
  const {editing, isDefault = false} = props;
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve(false);

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();

  const filteredOrganizations = useMemo(
    () => inputOrganizations.organizations.filter(org => org.parentId === undefined),
    [inputOrganizations.organizations]
  );
  const [formState, updateFormState] = useObjectState<FormState>(
    editing ? getFormStateForCode(editing) : {...defaultFormState, isDefault}
  );

  const defaultOrganization = props.organization || filteredOrganizations[0];
  const [organization, setOrganization] = useState((editing && editing.organization) || defaultOrganization);
  const [features, setFeatures] = useState<SupportedFeature[]>((editing && editing.supportedFeatures) || []);
  const [hasCurrentHarmonics, setHasCurrentHarmonics] = useState(
    editing ? editing.currentHarmonics !== undefined : false
  );
  const [currentHarmonics, setCurrentHarmonics] = useState((editing && editing.currentHarmonics) || HARMONICS);
  const [hasVoltageHarmonics, setHasVoltageHarmonics] = useState(
    editing ? editing.voltageHarmonics !== undefined : false
  );
  const [voltageHarmonics, setVoltageHarmonics] = useState((editing && editing.voltageHarmonics) || HARMONICS);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const handleClickAdd = async () => {
    if (formState.contractYears.numberValue === null) return;

    const currentHarmonics2 = hasCurrentHarmonics ? currentHarmonics : undefined;
    const voltageHarmonics2 = hasVoltageHarmonics ? voltageHarmonics : undefined;

    if (editing !== undefined) {
      try {
        setLoading(true);
        await api.activationCodes.update(editing.code, {
          name: formState.name,
          contractYears: formState.contractYears.numberValue || 1,
          isDefault: formState.isDefault,
          supportedTypes: [
            SupportedType.Energy,
            SupportedType.Solar,
            SupportedType.Infinity,
            SupportedType.Pro,
            SupportedType.Plus,
            SupportedType.WifiConnect
          ],
          supportedFeatures: features,
          currentHarmonics: currentHarmonics2,
          voltageHarmonics: voltageHarmonics2
        });
        setLoading(false);
        resolve(true);
        NotificationManager.success(T('activationCodes.update.success'));
        setError(undefined);
      } catch {
        setLoading(false);
        setError(T('activationCodes.update.failed'));
      }
    } else {
      if (organization === undefined) return;

      const activationCode: ICreateActivationCodeRequest = {
        organizationId: organization.id,
        name: formState.name,
        reusable: true,
        isDefault: formState.isDefault,
        contractYears: formState.contractYears.numberValue || 1,
        supportedTypes: [
          SupportedType.Energy,
          SupportedType.Solar,
          SupportedType.Infinity,
          SupportedType.Pro,
          SupportedType.Plus,
          SupportedType.WifiConnect
        ],
        features,
        currentHarmonics: currentHarmonics2,
        voltageHarmonics: voltageHarmonics2
      };

      // Create activation code
      try {
        const items = await api.activationCodes.create(activationCode);
        setLoading(false);
        if ((items || []).length > 0) {
          resolve(true);
          NotificationManager.success(T('activationCodes.create.success'));
          setError(undefined);
        } else {
          setError(T('activationCodes.error.creationFailed'));
        }
      } catch {
        setError(T('activationCodes.create.failed'));
        setLoading(false);
      }
    }
  };

  const featureSelectors = SupportedFeatures.map(feature => (
    <FeatureSelector key={feature} feature={feature} features={features} onChange={setFeatures} />
  ));

  return (
    <FormProvider>
      <Modal isOpen={isOpen} toggle={handleClose} size="xl" autoFocus={false} loading={loading}>
        <ModalHeader toggle={handleClose}>{T('activationCodes.create')}</ModalHeader>
        <ModalBody>
          <OrganizationInputGroup
            label={T('activationCodes.organization')}
            name="organization"
            organizations={filteredOrganizations}
            value={organization}
            onChange={useCallback(org => org && setOrganization(org), [])}
            onUpdateQuery={updateOrganizationInputQuery}
            disabled={editing !== undefined}
          />
          <TextInputGroup
            name="name"
            label={T('activationCodes.field.name')}
            value={formState.name}
            validate={validateRequired}
            onChange={name => updateFormState({name})}
          />
          <NumberInputGroup
            name="contractYears"
            label={T('activationCodes.field.contractYears')}
            value={formState.contractYears}
            onChange={contractYears => updateFormState({contractYears})}
            suffix={T('activationCodes.years')}
          />
          <Checkbox
            id="default"
            name="default"
            label={T('activationCodes.field.default')}
            checked={formState.isDefault}
            onCheckedChange={isDefault => updateFormState({isDefault})}
            testId="default"
          />
          <h4>{T('activationCodes.features')}</h4>
          {featureSelectors}
          <h4>{T('activationCodes.harmonics')}</h4>
          <table className={classes('table table-bordered', styles.harmonicsTable)}>
            <thead>
              <tr>
                <td />
                <td>Enabled</td>
                {HARMONICS.map(x => (
                  <td key={x}>{x}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              <HarmonicsSelectorRow
                enabled={hasCurrentHarmonics}
                selected={currentHarmonics}
                title="Current harmonics:"
                onEnabledChanged={setHasCurrentHarmonics}
                onSelectedChanged={setCurrentHarmonics}
              />
              <HarmonicsSelectorRow
                enabled={hasVoltageHarmonics}
                selected={voltageHarmonics}
                title="Voltage harmonics:"
                onEnabledChanged={setHasVoltageHarmonics}
                onSelectedChanged={setVoltageHarmonics}
              />
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter error={error}>
          <FormSaveButton onSave={handleClickAdd}>
            {editing ? T('activationCodes.update') : T('activationCodes.create')}
          </FormSaveButton>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
}
