import React, {useState} from 'react';

import {isAPIResponse} from '../../api/APIClient';
import {CheckRFIDCardResponse} from '../../api/OrganizationAPI';
import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {PasswordInputGroup} from '../../components/inputs/PasswordInputGroup';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {Label} from '../../components/ui/label';
import {RadioGroup, RadioGroupItem} from '../../components/ui/radio-group';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IOrganization} from '../../models/Organization';
import {RFIDCardProvider} from '../../models/RFIDCard';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {translateError} from '../../utils/Errors';
import {FormContextProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {useDelayedEffect} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {useObjectState} from '../../utils/ObjectState';
import {validateAtLeast, validateEmail, validateRFID, validateUsername} from '../../utils/Validation';

import styles from '../CommonCardStyles.module.scss';

import {getCreateCardError} from './model';

interface CreateChargingTokenModalProps extends IPromiseModalProps<boolean> {
  organization: IOrganization;
}

interface FormState {
  rfid: string;
  username: string;
  name: string;
  employeeNumber: string;
  provider: RFIDCardProvider;
  createNew: boolean;
  firstName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const defaultFormState: FormState = {
  rfid: '',
  username: '',
  name: '',
  employeeNumber: '',
  provider: RFIDCardProvider.Smappee,
  createNew: false,
  firstName: '',
  email: '',
  password: '',
  confirmPassword: ''
};

const defaultCheckState: CheckRFIDCardResponse = {
  exists: false
};

export function CreateChargingTokenModal(props: CreateChargingTokenModalProps) {
  const {organization} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const form = useFormState();
  const [state, updateFormState] = useObjectState(defaultFormState);
  const [checkResult, setCheckResult] = useState(defaultCheckState);

  const handleClickedAdd = async () => {
    if (form.hasErrors()) {
      form.showErrors();
      return '';
    }

    return api.organizations
      .createPublicChargingToken(organization.id, {
        name: state.name,
        employeeNumber: state.employeeNumber,
        ...(hasFeature(AppFeature.SocialLogin)
          ? {userEmailAddress: state.createNew ? undefined : state.email}
          : {userName: state.createNew ? undefined : state.username}),
        createUser: state.createNew
          ? {
              ...(hasFeature(AppFeature.SocialLogin)
                ? {
                    userName: state.email
                  }
                : {userName: state.username}),
              firstName: state.firstName,
              emailAddress: state.email,
              password: state.password
            }
          : undefined,
        value: state.rfid,
        provider: state.provider
      })
      .then(() => {
        resolve(true);
        return undefined;
      })
      .catch((err: unknown) => {
        const [field, message] = getCreateCardError(err, state.username, state.email);
        if (field.length > 0) form.setServerError(field, message);
        return field.length > 0 ? '' : message;
      });
  };

  useDelayedEffect(
    () => {
      api.organizations
        .checkCard(organization.id, state.rfid)
        .then(result => setCheckResult(result || defaultCheckState))
        .catch(() => setCheckResult(defaultCheckState));
    },
    [organization, state.rfid],
    500
  );

  const handleUserConnectChange = (value: string) => {
    if (value === 'existing') {
      updateFormState({createNew: false});
    } else {
      updateFormState({createNew: true});
    }
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={T('publicChargingTokens.add.title')}
      action={handleClickedAdd}
      actionText={T('publicChargingTokens.add.save')}
    >
      <FormContextProvider value={form}>
        <TextInputGroup
          form={form}
          name="rfid"
          label={T('publicChargingTokens.field.value')}
          value={state.rfid}
          onChange={rfid => updateFormState({rfid})}
          validate={validateRFID}
        />
        {checkResult.exists ? (
          <p>
            {checkResult.publicCharging
              ? T('publicChargingTokens.exists.alreadyActivated')
              : T('publicChargingTokens.exists.willActivate')}
          </p>
        ) : (
          <>
            <TextInputGroup
              form={form}
              name="name"
              label={T('publicChargingTokens.field.name')}
              value={state.name}
              onChange={name => updateFormState({name})}
            />
            <TextInputGroup
              form={form}
              name="employeeNumber"
              label={T('publicChargingTokens.field.employeeNumber')}
              value={state.employeeNumber}
              onChange={employeeNumber => updateFormState({employeeNumber})}
            />
            {!hasFeature(AppFeature.SocialLogin) && (
              <>
                <Label className="tw-font-bold">{T('publicChargingTokens.user')}</Label>
                <RadioGroup onValueChange={handleUserConnectChange}>
                  <div className={styles.radioWrapper}>
                    <RadioGroupItem
                      id="existing"
                      value="existing"
                      checked={!state.createNew}
                      className={styles.radio}
                    />
                    <Label className={styles.radioLabel} htmlFor="existing">
                      {T('publicChargingTokens.user.existing')}
                    </Label>
                  </div>
                  <div className={styles.radioWrapper}>
                    <RadioGroupItem id="create" value="create" checked={state.createNew} className={styles.radio} />
                    <Label className={styles.radioLabel} htmlFor="create">
                      {T('publicChargingTokens.user.create')}
                    </Label>
                  </div>
                </RadioGroup>
              </>
            )}
            {hasFeature(AppFeature.SocialLogin) ? (
              <TextInputGroup
                form={form}
                name="email"
                label={T('publicChargingTokens.user.email')}
                value={state.email}
                validate={validateEmail}
                onChange={email => updateFormState({email})}
              />
            ) : (
              <TextInputGroup
                form={form}
                name="username"
                label={T('publicChargingTokens.user.username')}
                value={state.username}
                onChange={username => updateFormState({username})}
              />
            )}
            {state.createNew && (
              <>
                <TextInputGroup
                  form={form}
                  name="firstname"
                  label={T('publicChargingTokens.user.firstName')}
                  value={state.firstName}
                  onChange={firstName => updateFormState({firstName})}
                />
                {!hasFeature(AppFeature.SocialLogin) && (
                  <TextInputGroup
                    form={form}
                    name="email"
                    label={T('publicChargingTokens.user.email')}
                    value={state.email}
                    onChange={email => updateFormState({email})}
                    validate={validateEmail}
                  />
                )}
                <PasswordInputGroup
                  name="password"
                  label={T('publicChargingTokens.user.password')}
                  value={state.password}
                  onChange={password => updateFormState({password})}
                  validate={validateAtLeast(8)}
                />
                <PasswordInputGroup
                  name="confirm-password"
                  label={T('publicChargingTokens.user.confirmPassword')}
                  value={state.confirmPassword}
                  onChange={confirmPassword => updateFormState({confirmPassword})}
                  validate={value => (value === state.password ? undefined : T('changePassword.mustMatch'))}
                />
              </>
            )}
          </>
        )}
      </FormContextProvider>
    </SingleActionModal>
  );
}
