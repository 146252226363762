import {RowActions} from '../../components/bootstrap';
import {Button} from '../../components/ui/button';
import Hammer from '../../components/ui-lib/icons/small/Hammer';
import {IChargingRule} from '../../models/ChargingPriorities';
import {IChargingSession, translateChargingStationPaymentType} from '../../models/ChargingStation';
import {
  ITableField,
  TimestampField,
  CalculatedStringField,
  NumberField,
  StringField,
  ComponentField,
  IntegerField,
  CurrencyField,
  CalculatedCurrencyField,
  CalculatedDurationField,
  CheckboxField
} from '../../models/Table';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {T} from '../../utils/Internationalization';

export function getTableColumns(
  whitelist: IChargingRule[],
  chargingPark: boolean,
  serviceDesk: boolean = false,
  onFix: (row: IChargingSession) => void = () => {},
  selected?: IChargingSession[],
  onChecked?: (session: IChargingSession, checked: boolean) => void
): ITableField<IChargingSession>[] {
  const result: ITableField<IChargingSession>[] = [];

  if (serviceDesk) {
    result.push(new IntegerField('id', 'ID'));
  }

  if (chargingPark) {
    result.push(
      new CalculatedStringField('stationSerial', T('chargingSession.stationSerial'), session => {
        return session.station && session.station.serialNumber;
      }),
      new CalculatedStringField('stationName', T('chargingSession.stationName'), session => {
        if (session.station === undefined) return undefined;

        return session.station.name;
      })
    );
  }

  const isChecked = (row: IChargingSession) => {
    return selected === undefined ? false : selected.some(item => item.id === row.id);
  };

  result.push(
    new TimestampField('from', T('chargingSession.from'), {
      format: 'YYYY-MM-DD HH:mm'
    }),
    new TimestampField('to', T('chargingSession.to'), {
      format: 'YYYY-MM-DD HH:mm'
    }),
    new CalculatedDurationField(
      'duration',
      T('chargingSession.duration'),
      session => {
        let toValue: number;
        if (!session.to) {
          toValue = Date.now();
        } else {
          toValue = session.to;
        }

        return toValue - session.from;
      },
      {unit: 'h:mm'},
      session => {
        if (!session.to) {
          return ` ${T('chargingSession.ongoing')}`;
        } else {
          return '';
        }
      }
    ),
    new NumberField('energy', T('chargingSession.consumption'), {digitsAfterComma: 2}, 'kWh'),
    new CalculatedStringField('paymentType', T('chargingSession.paymentType'), session =>
      translateChargingStationPaymentType(session.paymentType, !!session.splitBillingAgreement)
    ),
    new CurrencyField('cost', T('chargingSession.cost'), session => session.currency || 'EUR', {
      tooltip: T('chargingSession.cost.info')
    }),
    new CalculatedCurrencyField(
      'hostCost',
      T('chargingSession.hostCost'),
      session => {
        if (session.hostCost === undefined) {
          return undefined;
        }
        return session.hostCost * (1 - (session.discount || 0));
      },
      session => session.currency || 'EUR',
      {
        tooltip: T('chargingSession.hostCost.info')
      }
    ),
    new CalculatedCurrencyField(
      'commissionFee',
      T('chargingSession.commissionFee'),
      session => {
        if (session.operatorCost === undefined || session.hostCost === undefined) {
          return undefined;
        }

        return session.operatorCost - session.hostCost * (1 - (session.discount || 0));
      },
      session => session.currency || 'EUR',
      {
        tooltip: T('chargingSession.commissionFee.info')
      }
    ),
    new CalculatedCurrencyField(
      'emspFee',
      T('chargingSession.emspFee'),
      session => session.emspFee,
      session => session.currency || 'EUR'
    ),
    new CalculatedStringField('side', T('chargingSession.side'), session => session.controller?.label || ''),
    new CalculatedStringField('rfid', T('chargingSession.rfid'), item => {
      if (item.rfid === undefined) {
        return undefined;
      }

      const rule = whitelist.find(x =>
        x.rfid ? x.rfid.value.toLocaleUpperCase() === item.rfid?.toLocaleUpperCase() : false
      );
      return item.rfid + (rule ? ` (${rule.rfid!.comment})` : '');
    })
  );

  if (selected !== undefined && onChecked !== undefined) {
    result.splice(
      0,
      0,
      new CheckboxField('selected', '', isChecked, onChecked, {
        autoInsert: 'start',
        width: 20
      })
    );
  }

  if (!hasFeature(AppFeature.HideUsernames)) {
    result.push(new StringField('userName', T('chargingSession.userName')));
  }
  if (hasFeature(AppFeature.SocialLogin)) {
    result.push(
      new CalculatedStringField('emailAddress', T('chargingSession.emailAddress'), item => {
        return item.user?.emailAddress;
      })
    );
  }

  if (serviceDesk) {
    result.push(new IntegerField('csmsTransactionId', 'CSMS Transaction ID'), new StringField('csmsUrl', 'CSMS Url'));
    result.push(
      new ComponentField('actions', T('generic.actions'), item => {
        return (
          <RowActions className="!tw-flex !tw-justify-center !tw-items-center !tw-gap-2">
            <Button
              variant="ghost"
              title="Fix"
              onClick={() => onFix(item)}
              className="!tw-w-6 !tw-h-6 !tw-rounded-none !tw-justify-center !tw-p-0"
            >
              <Hammer width={16} height={16} />
            </Button>
          </RowActions>
        );
      })
    );
  }

  return result;
}
