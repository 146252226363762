import {T} from '../utils/Internationalization';
import {TranslationKey} from '../utils/TranslationTerms';

import {AuthUser, getUserRole, IAuthUser} from './AuthUser';
import {ContractInfo} from './Contract';
import {IRoleType} from './Location';
import {IOrganization} from './Organization';
import {RFIDCard} from './RFIDCard';

export const enum UserType {
  Anonymous = '__ANONYMOUS', // special type, only used in front-end

  BasicAdmin = 'BASIC',
  BasicReadOnly = 'READONLY',
  PartnerViewer = 'MULTISITE_BASIC',
  PartnerAdmin = 'MULTISITE_HELPDESK',
  PartnerAPI = 'MULTISITE_API',
  ServiceDesk = 'HELPDESK',
  PartnerReadOnly = 'MULTISITE_READONLY',
  M2MAPI = 'M2M_API'
}

const UserTypeNames: {[key: string]: TranslationKey} = {
  [UserType.BasicAdmin]: 'role.basic',
  [UserType.BasicReadOnly]: 'role.basicReadonly',
  [UserType.PartnerViewer]: 'role.partnerViewer',
  [UserType.PartnerAdmin]: 'role.partnerAdmin',
  [UserType.PartnerAPI]: 'role.partnerAPI',
  [UserType.ServiceDesk]: 'role.serviceDesk',
  [UserType.PartnerReadOnly]: 'role.partnerReadOnly',
  [UserType.M2MAPI]: 'role.m2mAPI'
};

const RoleToTypeMap = {
  HELPDESK: UserType.PartnerAdmin,
  API: UserType.PartnerAPI,
  OBSERVER: UserType.PartnerReadOnly,
  MANAGED: UserType.BasicAdmin,
  OTHER: UserType.BasicAdmin,
  ACCOUNTANT: UserType.PartnerAdmin,
  INSTALLER: UserType.PartnerAdmin
};

export function getUserTypeDisplayName(type: UserType): string {
  return T(UserTypeNames[type]) || type;
}

export function getRoleTypeDisplayName(role?: string): string {
  return getUserTypeDisplayName(role ? RoleToTypeMap[role as keyof typeof RoleToTypeMap] : UserType.BasicAdmin);
}

export interface IUser {
  archivedOn?: number;
  id: number;
  userName?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  emailAddressVerified?: boolean;
  country?: string;
  confirmed?: boolean;
  type: UserType;
  language?: string;
  role?: string;
  apiAccess?: {
    appName: string;
    clientId: string;
    clientSecret: string;
  };
  invitationId?: string;
  invitationExpired?: boolean;
}

export function hasOrganizationAccess(role?: string): boolean {
  return role !== 'MANAGED';
}

export function hasPartnerFunctionality(user: AuthUser, organizations: IOrganization[]): boolean {
  if (user.isServiceDesk()) {
    return true;
  }
  return organizations.some(org => org.role !== undefined && org.role !== 'MANAGED');
}

export function hasPartnerAdminFunctionality(user: AuthUser, organizations: IOrganization[]): boolean {
  if (user.isServiceDesk()) {
    return true;
  }
  return organizations.some(org => org.role === 'HELPDESK');
}

export function isReadOnlyInOrganization(
  user: AuthUser,
  organization?: IOrganization,
  organizations?: IOrganization[]
): boolean {
  if (user.isServiceDesk()) {
    return false;
  } else if (organization === undefined) {
    return true;
  }

  const role =
    organization.role === undefined ? organizations?.find(org => org.id === organization.id)?.role : organization.role;
  return role === undefined || role === 'OBSERVER' || role === 'MANAGED';
}

export function getUserFromAuthUser(user: IAuthUser): IUser {
  return {
    id: user.userId,
    userName: user.userName,
    firstName: user.firstName,
    lastName: user.lastName,
    emailAddress: user.emailAddress,
    language: user.language,
    confirmed: true,
    type: getUserRole(user.userType)
  };
}

export interface ICreateUserRequest {
  firstName: string;
  lastName?: string;
  userName: string;
  password: string;
  emailAddress: string;
  country: string;
  type: UserType;
  role?: IRoleType['id'];
  organization?: number;
}

export interface IFindOrCreateUserRequest {
  firstName: string;
  lastName?: string;
  userName?: string;
  password: string;
  emailAddress: string;
  country?: string;
  type: UserType;
  organization: number;
  apiAccess: {
    appName: string;
    clientId: string;
    clientSecret: string;
  };
}

export interface IFindOrCreateUserResponse {
  id: number;
  firstName: string;
  userName: string;
  lastName?: string;
  emailAddress: string;
  type: UserType;
  apiAccess?: {
    appName: string;
    clientId: string;
    clientSecret: string;
  };
}

interface RoleDefinition {
  value: UserType;
  label: TranslationKey;
  helpdeskOnly: boolean;
  available: boolean;
}

export class User {
  static fromJSON(user: IUser) {
    return new User(
      user.id || null,
      user.userName || '',
      user.firstName || '',
      user.lastName || '',
      user.emailAddress || '',
      user.country,
      user.type,
      undefined,
      user.emailAddressVerified
    );
  }

  static empty() {
    return new User(null, '', '', '', '', '', UserType.BasicAdmin);
  }

  static ROLES: RoleDefinition[] = [
    {
      value: UserType.BasicReadOnly,
      label: 'role.basicReadonly',
      helpdeskOnly: false,
      available: false
    },
    {
      value: UserType.BasicAdmin,
      label: 'role.basic',
      helpdeskOnly: false,
      available: true
    },
    {
      value: UserType.PartnerViewer,
      label: 'role.partnerViewer',
      helpdeskOnly: false,
      available: false
    },
    {
      value: UserType.PartnerReadOnly,
      label: 'role.partnerReadOnly',
      helpdeskOnly: false,
      available: true
    },
    {
      value: UserType.PartnerAdmin,
      label: 'role.partnerAdmin',
      helpdeskOnly: false,
      available: true
    },
    {
      value: UserType.PartnerAPI,
      label: 'role.partnerAPI',
      helpdeskOnly: true,
      available: true
    },
    {
      value: UserType.ServiceDesk,
      label: 'role.serviceDesk',
      helpdeskOnly: true,
      available: true
    }
  ];

  static ROLENAME_BY_ROLE: {[key: string]: TranslationKey} = User.ROLES.reduce(
    (roles: {[key: string]: TranslationKey}, role) => {
      roles[role.value] = role.label;
      return roles;
    },
    {}
  );

  id: number | null;
  userName: string;
  firstName: string;
  lastName?: string;
  email: string;
  country?: string;
  role: UserType;
  password?: string;
  emailAddressVerified?: boolean;

  constructor(
    id: number | null,
    userName: string,
    firstName: string,
    lastName: string,
    email: string,
    country: string | undefined,
    role: UserType,
    password?: string,
    emailAddressVerified?: boolean
  ) {
    this.id = id;
    this.userName = userName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.country = country;
    this.role = role;
    this.password = password; // only used for updating passwords
    this.emailAddressVerified = emailAddressVerified;
  }

  isNewUser() {
    return this.id === null;
  }

  hasUserName() {
    return this.userName !== '';
  }

  getName() {
    return this.firstName || this.userName || this.email;
  }

  getUserTypeDisplayName() {
    return T(User.ROLENAME_BY_ROLE[this.role]) || this.role;
  }

  matches(queryLower: string) {
    return (
      this.userName.toLowerCase().includes(queryLower) ||
      this.firstName.toLowerCase().includes(queryLower) ||
      this.email.toLowerCase().includes(queryLower)
    );
  }

  toJSON(): IUser {
    return {
      id: this.id || 0,
      userName: this.userName,
      firstName: this.firstName,
      lastName: this.lastName,
      emailAddress: this.email,
      country: this.country,
      type: this.role,
      emailAddressVerified: this.emailAddressVerified
    };
  }

  clone() {
    return new User(
      this.id,
      this.userName,
      this.firstName,
      this.lastName || '',
      this.email,
      this.country,
      this.role,
      this.password,
      this.emailAddressVerified
    );
  }
}

export interface UserDetails {
  contracts: ContractInfo[];
  cards: RFIDCard[];
}

export interface SSOLink {
  type: 'APPLE' | 'GOOGLE' | 'MICROSOFT' | 'SMAPPEE';
  active: boolean;
  email: 'string';
}
