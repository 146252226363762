import dayjs from 'dayjs';

import {ActivationCodeAPI, ActivationCodeCache} from '../api/ActivationCodeAPI';
import {APIClient} from '../api/APIClient';
import {AppsAPI} from '../api/AppsAPI';
import {BillingAPI} from '../api/BillingsAPI';
import {ChargingPrioritiesAPI} from '../api/ChargingPrioritiesAPI';
import {ChargingSessionsAPI} from '../api/ChargingSessionsAPI';
import {ChargingStationAPI, ChargingStationCache} from '../api/ChargingStationAPI';
import {ContractsAPI} from '../api/ContractsAPI';
import {DashboardAPI} from '../api/DashboardAPI';
import {FluviusAPI} from '../api/FluviusAPI';
import {InvitationAPI} from '../api/InvitationAPI';
import {LoadManagementAPI} from '../api/LoadManagementAPI';
import {LocationAPI, LocationsCache} from '../api/LocationAPI';
import {OAuthAPI} from '../api/OAuthAPI';
import {OperatorAPI} from '../api/OperatorAPI';
import {OrganizationAPI, OrganizationsCache} from '../api/OrganizationAPI';
import {PricingGroupAPI} from '../api/PricingGroupAPI';
import {PricingPolicyAPI} from '../api/PricingPolicyAPI';
import {ServiceUtilsAPI} from '../api/ServiceUtilsAPI';
import {SibelgaAPI} from '../api/SibelgaAPI';
import {SmartDeviceAPI} from '../api/SmartDeviceAPI';
import {TariffAPI} from '../api/TariffAPI';
import {UserAPI, UserAPICache} from '../api/UserAPI';
import {ActivePeriod} from '../components/PeriodSelector';
import {IActivationCode} from '../models/ActivationCode';
import {IAppDashboard} from '../models/AppDashboard';
import {IAppliance} from '../models/Appliance';
import {IApplianceType} from '../models/ApplianceType';
import {AuthUser, IAuthUser} from '../models/AuthUser';
import {Automation, IAutomationTarget, AutomationAction} from '../models/Automation';
import {IBatteryManufacturer} from '../models/BatteryModels';
import {IChannelUpdateResult} from '../models/Channel';
import {ChargingDisplayImage, ChargingDisplayImageType} from '../models/ChargingDisplayImage';
import {IChargingRule} from '../models/ChargingPriorities';
import {ChargingSessionExport} from '../models/ChargingSessionExport';
import {
  CreateChargingStationRequest,
  CreateChargingStationResult,
  IChargingSession,
  IChargingStationConfiguration,
  IChargingStationCreateModuleRequest,
  ChargingStationPaymentTypeFilter
} from '../models/ChargingStation';
import {IChargingStationDiagnosticEvent} from '../models/ChargingStationDiagnostics';
import {IChargingStationInternalCondition} from '../models/ChargingStationInternalCondition';
import {IConsumptionValue} from '../models/ConsumptionValue';
import {ICustomFile, ICustomFileFields} from '../models/CustomFile';
import {IDailyMeterReadings} from '../models/DailyMeterReading';
import {Device, IDeviceOutputConfiguration} from '../models/Device';
import {IDeviceActivationHistoryEntry} from '../models/DeviceActivationHistory';
import {IDeviceConfiguration, IDeviceConfigurationState} from '../models/DeviceConfiguration';
import {IDeviceIncident} from '../models/DeviceIncident';
import {IDeviceInfo} from '../models/DeviceInfo';
import {DeviceType} from '../models/DeviceType';
import {IEventList} from '../models/Event';
import {IGasWaterReading} from '../models/GasWaterReading';
import {HarmonicsData} from '../models/Harmonics';
import {IHighLevelConfiguration, IConfiguration, PhaseType} from '../models/HighLevelConfiguration';
import {IHomeControlDevices} from '../models/HomeControlDevice';
import {ILegacyChannel, getLoadTypeFromConsumptionType} from '../models/LegacyChannel';
import {ILoad, ILoadUpdateResult, ILoadChannel, LoadType, ILoadCreationSpec} from '../models/Load';
import {
  ICreateLocationRequest,
  IUpdateLocationRequest,
  ILocationSummary,
  ILocationSearchResult,
  ILocationMetaInfo,
  IGasWaterDevice,
  IRoleType
} from '../models/Location';
import {ILocationSurvey} from '../models/LocationSurvey';
import {IQueriedLocationUser} from '../models/LocationUser';
import {IMessage} from '../models/Message';
import {
  IOrganization,
  ISearchField,
  ISearchFieldValue,
  IOrganizationRegion,
  IMeasuringCase
} from '../models/Organization';
import {
  IOverloadProtectionConfigMultiGrid,
  IUpdateOverloadProtectionConfigurationRequest
} from '../models/OverloadProtection';
import {getPhaseLabel, PHASES} from '../models/Phase';
import {IPreconfigurationKit} from '../models/PreconfigurationKit';
import {IPushMessageContext} from '../models/PushMessage';
import {IRetentionPolicy, IUpdateRetentionPolicyRequest} from '../models/RetentionPolicy';
import {ISensorReadingsMulti, SensorReadingType} from '../models/SensorReadings';
import {ISmartDevice, IConfigurationProperty, ICapacityProtectionConfiguration} from '../models/SmartDevice';
import {SplitBillingAgreement, SplitBillingAgreementCreateRequest} from '../models/SplitBillingAgreement';
import {ISwitch, ISwitchReading} from '../models/Switch';
import {SpotPriceMarket} from '../models/Tariff';
import {ITimezone} from '../models/Timezone';
import {IUploadedFile} from '../models/UploadedFile';
import {
  Interval,
  UsageType,
  UsageUnit,
  IUsageList,
  getLegacyAggregationType,
  getAggregationTypeFromLegacy,
  convertToV2,
  IApplianceUsageList
} from '../models/UsageValue';
import {ICreateUserRequest, IUser, IFindOrCreateUserRequest, User} from '../models/User';
import {IUserPreference} from '../models/UserPreference';
import {ValidationError, IValidationError} from '../models/ValidationError';
import {AppStore} from '../redux';
import {AppFeature, hasFeature} from '../utils/AppParameters';
import {None} from '../utils/Arrays';
import {DataCache} from '../utils/DataCache';
import {TimeRange} from '../utils/OpeningHours';
import {RepeatableAbortController} from '../utils/RepeatableAbortController';
import * as StringUtils from '../utils/StringUtils';

const API_REMOTE = 'http://remote.smappee.net';

function getUsageInterval(interval: Interval) {
  switch (interval) {
    case Interval.MONTH:
      return Interval.MONTH;
    case Interval.WEEK:
      return Interval.WEEK;
    default:
      return Interval.DAY;
  }
}

export enum RemoteType {
  HTTP = 'HTTP',
  NODERED = 'NODE_RED',
  SSH = 'SSH',
  MQTT = 'MQTT'
}

// Intervals (LEGACY)
const INTERVAL_LEGACY_MAP: {[key: string]: string} = {
  [Interval.MINUTES_5]: '1',
  [Interval.HOUR]: '2',
  [Interval.DAY]: '3',
  [Interval.MONTH]: '4'
};

export interface IProcessedUsage {
  timestamp: number;
  unit: UsageUnit;
  values: {[key: string]: number | null};
}

export interface IProcessedUsageList {
  items: IProcessedUsage[];
  units: {[key: string]: UsageUnit};
  activeInterval: Interval;
}

export interface APIResponse<T> {
  data?: T;
  statusCode: number;
  success: boolean;
  code?: string;
  error?: string;
}

export interface UserLoginResponse {
  response: number;
  url?: string;
  user?: IAuthUser;
}

class APICache {
  nonCancellableAPI?: API;

  version?: Promise<string>;

  applianceTypes?: Promise<IApplianceType[]>;
  highLevelConfigurations: DataCache<number, IHighLevelConfiguration>;
  switches: DataCache<number, ISwitch[]>;
  sensors: DataCache<number, IGasWaterDevice[]>;
  locationActivationCodes: DataCache<number, IActivationCode | undefined>;
  retentionPolicies: DataCache<number, IRetentionPolicy>;
  userOrganizations: DataCache<number, IOrganization[]>;
  appDashboards: DataCache<number, IAppDashboard>;
  roleTypes?: Promise<IRoleType[]>;

  public constructor() {
    // Cached values
    this.highLevelConfigurations = new DataCache<number, IHighLevelConfiguration>('highLevelConfiguration', id =>
      this.nonCancellableAPI!.getHighLevelConfigurationInternal(id)
    );
    this.switches = new DataCache<number, ISwitch[]>('switches', id => this.nonCancellableAPI!.getSwitchesInternal(id));
    this.sensors = new DataCache<number, IGasWaterDevice[]>('sensors', id =>
      this.nonCancellableAPI!.getSensorsInternal(id)
    );
    this.locationActivationCodes = new DataCache<number, IActivationCode | undefined>('locationActivationCodes', id =>
      this.nonCancellableAPI!.internalGetLocationActivationCode(id)
    );
    this.retentionPolicies = new DataCache<number, IRetentionPolicy>('retentionPolicies', id =>
      this.nonCancellableAPI!.getRetentionPolicyInternal(id)
    );
    this.userOrganizations = new DataCache<number, IOrganization[]>('userOrganizations', id =>
      this.nonCancellableAPI!.getUserOrganizationsInternal(id)
    );
    this.appDashboards = new DataCache<number, IAppDashboard>('dashboard', id =>
      this.nonCancellableAPI!.getAppDashboardInternal(id)
    );
  }
}

class APICaches {
  activationCodes: ActivationCodeCache;
  chargingStations: ChargingStationCache;
  locations: LocationsCache;
  organizations: OrganizationsCache;
  user: UserAPICache;

  constructor(client: APIClient) {
    this.chargingStations = new ChargingStationCache(client);
    this.activationCodes = new ActivationCodeCache(client);
    this.locations = new LocationsCache(client);
    this.organizations = new OrganizationsCache(client);
    this.user = new UserAPICache();
  }
}

class API {
  static create(
    store: AppStore | undefined,
    backend: (url: string, options: any) => Promise<any>,
    onAuthenticationFailure: () => void,
    onMaintenance: () => void
  ) {
    const cache = new APICache();
    const client = APIClient.create(store, backend, onAuthenticationFailure, onMaintenance);
    const caches = new APICaches(client);
    const api = new API(client, cache, caches);
    cache.nonCancellableAPI = api;
    return api;
  }

  private client: APIClient;
  private cache: APICache;
  private caches: APICaches;

  constructor(client: APIClient, cache: APICache, caches: APICaches) {
    this.client = client;
    this.cache = cache;
    this.caches = caches;
  }

  get activationCodes() {
    return new ActivationCodeAPI(this.client, this.caches.activationCodes);
  }

  get apps() {
    return new AppsAPI(this.client);
  }

  get chargingPriorities() {
    return new ChargingPrioritiesAPI(this.client);
  }

  get chargingStations() {
    return new ChargingStationAPI(this.client, this.caches.chargingStations);
  }

  get chargingSessions() {
    return new ChargingSessionsAPI(this.client);
  }

  get contracts() {
    return new ContractsAPI(this.client);
  }

  get dashboard() {
    return new DashboardAPI(this.client);
  }

  get fluvius() {
    return new FluviusAPI(this.client);
  }

  get invitations() {
    return new InvitationAPI(this.client);
  }

  get loadManagement() {
    return new LoadManagementAPI(this.client);
  }

  get oauth() {
    return new OAuthAPI(this.client);
  }

  get operators() {
    return new OperatorAPI(this.client);
  }

  get sibelga() {
    return new SibelgaAPI(this.client);
  }

  get user() {
    return new UserAPI(this.client, this.caches.user);
  }

  get organizations() {
    return new OrganizationAPI(this.client, this.caches.organizations);
  }

  get locations() {
    return new LocationAPI(this.client, this.caches.locations);
  }

  get pricingPolicies() {
    return new PricingPolicyAPI(this.client);
  }

  get pricingGroups() {
    return new PricingGroupAPI(this.client);
  }

  get serviceUtils() {
    return new ServiceUtilsAPI(this.client);
  }

  get smartDevices() {
    return new SmartDeviceAPI(this.client);
  }

  get tariffs() {
    return new TariffAPI(this.client);
  }

  get billings() {
    return new BillingAPI(this.client);
  }

  abort() {
    this.client.abort();
  }

  withAbort(controller?: RepeatableAbortController) {
    return controller ? new API(this.client.withAbort(controller), this.cache, this.caches) : this;
  }

  withAuthenticationFailureHandler(handler: () => void) {
    return new API(this.client.withAuthenticationFailureHandler(handler), this.cache, this.caches);
  }

  invalidateLocation(id: number) {
    this.caches.locations.invalidate(id);
    this.cache.locationActivationCodes.invalidate(id);
  }

  invalidateHighLevelConfiguration(locationId: number) {
    this.cache.highLevelConfigurations.invalidate(locationId);
  }

  getToken() {
    return this.client.getToken();
  }

  doRefreshLogin(language?: string) {
    return this.client.doRefreshLogin(language);
  }

  login(userName: string, password: string): Promise<UserLoginResponse> {
    return this.client.loginState
      .backend('/login', {
        credentials: 'include',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({userName, password})
      })
      .then((response: Response) => {
        const {status, url} = response;

        switch (status) {
          case 200:
            // Redirect to the provided URL
            return response.json().then(user => {
              this.client.loginState.setUser(user);
              return {user, url, response: status};
            });
          default:
            return Promise.resolve({response: status});
        }
      });
  }

  loginWithGoogle(idToken: string) {
    return fetch('/dashapi/login/sso/google', {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idToken
      })
    });
  }

  loginWithMicrosoft(idToken: string) {
    return fetch('/dashapi/login/sso/microsoft', {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idToken
      })
    });
  }

  loginWithApple(idToken: string, name?: {firstName: string; lastName: string}) {
    return fetch('/dashapi/login/sso/apple', {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idToken,
        userInfo: {name}
      })
    });
  }

  loginWithRefreshToken(token: string) {
    this.client.doRefreshLogin(undefined, token);
  }

  //
  // API functions
  //

  getServerVersion(): Promise<string> {
    if (this.cache.version === undefined) {
      this.cache.version = this.client.doGetText('/api/v10/cloud');
    }

    return this.cache.version;
  }

  getAppDashboard(locationId: number): Promise<IAppDashboard> {
    return this.cache.appDashboards.fetch(locationId);
  }

  getAppDashboardInternal(locationId: number): Promise<IAppDashboard> {
    const url = `/api/v10/user/dashboard/servicelocation/${locationId}`;
    return this.client.doGet(url);
  }

  getAppDashboardCards(locationId: number): Promise<unknown> {
    const url = `/api/v10/user/dashboard/servicelocation/${locationId}/cards`;
    return this.client.doGet(url);
  }

  getAppliances(locationId: number, includeUnknown: boolean = false): Promise<IAppliance[]> {
    let url = `/api/v10/servicelocation/${locationId}/appliances`;
    if (includeUnknown) url += '?includeUnknown=true';

    return this.client.doGet(url);
  }

  getApplianceTypes(): Promise<IApplianceType[]> {
    if (!this.cache.applianceTypes) {
      this.cache.applianceTypes = this._getApplianceTypes();
    }

    return this.cache.applianceTypes;
  }

  private async _getApplianceTypes(): Promise<IApplianceType[]> {
    const url = `/api/v10/appliancetypes`;
    let applianceTypes = await this.client.doGet<IApplianceType[]>(url);

    // Fix for inconsistent capitalization in backend
    for (let type of applianceTypes) {
      const {translation} = type;
      type.translation = StringUtils.capitalizeFirstLetter(translation);
    }

    // Sort result alphabetically
    applianceTypes.sort((a: IApplianceType, b: IApplianceType) => {
      return a.translation.localeCompare(b.translation);
    });
    return applianceTypes;
  }

  getApplianceCosts(locationId: number, from: number, to: number, interval: Interval) {
    const url = `/api/v10/servicelocation/${locationId}/bills?timestamps=${from}&intervalLength=${interval}`;
    return this.client.doGet(url);
  }

  getApplianceUsage(
    locationId: number,
    applianceId: string,
    from: number,
    to: number,
    interval: Interval
  ): Promise<IApplianceUsageList> {
    const url = `/api/v10/servicelocation/${locationId}/appliances/${applianceId}/usage?range=${from},${to}&aggregationType=${interval}`;
    return this.client.doGet(url);
  }

  requestRecoveryLink(userName: string, captcha: string): Promise<unknown> {
    const url = '/requestPasswordReset';
    return this.client.doPost(url, {userName, captcha});
  }

  requestUsernameRecoveryMail(email: string, captcha: string): Promise<unknown> {
    const url = `/requestUsernameRecovery`;
    return this.client.doPost(url, {email, captcha});
  }

  getUser(id: number): Promise<unknown> {
    const url = `/api/v10/users/${id}`;
    return this.client.doGet(url);
  }

  getCurrentUser(): Promise<User> {
    const url = `/api/v10/user`;
    return this.client.doGet(url);
  }

  getUserOrganizations(id: number, force: boolean = false): Promise<IOrganization[]> {
    return this.cache.userOrganizations.fetch(id, force);
  }

  getUserOrganizationsInternal(id: number): Promise<IOrganization[]> {
    const url = `/api/v10/users/${id}/organizations`;
    return this.client.doGet<IOrganization[]>(url).catch(() => []);
  }

  purgeOrganization(
    organizationId: number,
    replacementOrganization: number,
    replacementRFIDToken: string,
    deleteBillingHistory: boolean
  ): Promise<any> {
    const url = `/api/v10/organizations/${organizationId}/purge`;
    return this.client.doPost(url, {replacementOrganization, replacementRFIDToken, deleteBillingHistory});
  }

  updateCurrentUser(
    userName: string,
    firstName: string,
    lastName: string | undefined,
    email: string,
    termsOfUseAccepted?: boolean
  ): Promise<unknown> {
    const url = `/api/v10/users/${this.client.getUserId()}`;
    return this.client
      .doPatch<IUser>(url, {
        userName,
        firstName,
        lastName,
        emailAddress: email,
        ...(termsOfUseAccepted ? {termsOfUseAccepted} : {})
      })
      .then(result => {
        const user = this.client.loginState.me.serialize();
        user.userName = userName;
        user.firstName = firstName;
        user.lastName = lastName;
        user.emailAddress = email;

        if (termsOfUseAccepted) {
          user.termsOfUseAccepted = termsOfUseAccepted;
        }

        this.client.loginState.setUser(new AuthUser(user));
        return result;
      });
  }

  getMessages(userId: number | undefined): Promise<IMessage[]> {
    let url = `/api/v10/messages`;

    // Get messages based on location ID when supported
    if (userId) {
      url += `/helpdesk?userId=${userId}`;
    }

    return this.client.doGet(url);
  }

  markMessagesRead(): Promise<void> {
    const url = `/api/v10/messages/markread`;
    return this.client.doPut(url);
  }

  getEvents(
    locationId: number,
    pageSize: number,
    cursor?: string,
    includeUnknown: boolean = false
  ): Promise<IEventList> {
    let url = `/api/v10/servicelocation/${locationId}/recentevents?pageSize=${pageSize}`;
    if (cursor) url += `&cursor=${cursor}`;
    if (includeUnknown) url += `&includeUnknown=${includeUnknown}`;

    return this.client.doGet(url);
  }

  /** Loads a number of consumption values. The return values are always returned in sorted order by timestamp (low to high). */
  async getElectricityConsumption(locationId: number, period: ActivePeriod): Promise<IConsumptionValue[]> {
    const intervalString = convertToV2(period.interval);
    const url = `/api/v10/servicelocation/${locationId}/consumption?from=${period.from}&to=${period.to}&reportType=${intervalString}`;
    let data = await this.client.doGet<IConsumptionValue[]>(url, None);
    if (period.interval === Interval.YEAR) {
      // ONE_YEAR is returned instead
      data.forEach(entry => (entry.aggregation = period.interval));
    }

    return data || [];
  }

  getElectricityConsumptionFrom(
    endpoint: string,
    locationId: number,
    from: number,
    to: number,
    interval: Interval
  ): Promise<IConsumptionValue[]> {
    const intervalString = convertToV2(interval);
    const url = `${endpoint}/v10/servicelocation/${locationId}/consumption?from=${from}&to=${to}&reportType=${intervalString}`;
    return this.client.doGet<IConsumptionValue[]>(url);
  }

  getDailyMeterReadings(locationId: number, from: number, to: number): Promise<IDailyMeterReadings> {
    const url = `/api/v10/servicelocation/${locationId}/meterreadings/daily?range=${from},${to}`;
    return this.client.doGet<IDailyMeterReadings>(url);
  }

  getHarmonicsData(
    locationId: number,
    loads: number[],
    from: number,
    to: number,
    interval: Interval
  ): Promise<HarmonicsData> {
    const url =
      `/api/v10/servicelocation/${locationId}/usage/harmonics` +
      `?loads=${loads.join(',')}&range=${from},${to}&aggregationType=${interval}`;
    return this.client.doGet(url);
  }

  getRetentionPolicy(locationId: number, refresh: boolean = false): Promise<IRetentionPolicy> {
    return this.cache.retentionPolicies.fetch(locationId, refresh);
  }

  getRetentionPolicyInternal(locationId: number): Promise<IRetentionPolicy> {
    const url = `/api/v10/servicelocation/${locationId}/retention`;
    return this.client.doGet(url);
  }

  updateRetentionPolicy(
    locationId: number,
    body: IUpdateRetentionPolicyRequest
  ): Promise<IRetentionPolicy | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/retention`;
    this.cache.retentionPolicies.invalidate(locationId);
    return this.client.doPut(url, body);
  }

  deleteLocation(locationId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}`;
    return this.client.doDelete(url);
  }

  restoreLocation(locationId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/restore`;
    return this.client.doPost(url, {});
  }

  deactivateLocation(locationId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/deactivate`;
    return this.client.doPost(url, {});
  }

  reactivateLocation(locationId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/reactivate`;
    return this.client.doPost(url, {});
  }

  async remoteConnect(serialNumber: string, port: number, timeout: number, type: RemoteType = RemoteType.HTTP) {
    const url = `/api/v10/portal/connect`;
    const {success, statusCode} = await this.client.doPostWithStatus(url, {
      serialNumber,
      port,
      type,
      timeout
    });

    let connectionUrl = `${API_REMOTE}:${port}`;
    if (type === RemoteType.HTTP) connectionUrl += '/smappee.html';

    // Wait a second to give the server time to set up the connection
    await new Promise(resolve => setTimeout(resolve, 1000));
    return {success, statusCode, connectionUrl};
  }

  updateLocationFirmware(
    locationId: number,
    type: string,
    firmware: string
  ): Promise<{statusCode: number; success: boolean}> {
    const url = `/api/v10/servicelocation/${locationId}/devices/${type}/targetfirmware`;
    return this.client.doPutWithStatus(url, firmware);
  }

  clearLocationFirmwareTarget(locationId: number, type: string): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/devices/${type}/targetfirmware`;
    return this.client.doDelete(url);
  }

  deleteDevice(locationId: number, serialNumber: string) {
    const url = `/api/v10/servicelocation/${locationId}/configuration/smartsensors/${serialNumber}`;
    return this.client.doDelete(url);
  }

  deleteOutputModule(locationId: number, serialNumber: string) {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/output/${serialNumber}`;
    return this.client.doDelete(url);
  }

  createLocation(location: ICreateLocationRequest): Promise<unknown> {
    const url = `/api/v10/servicelocation/`;
    return this.client.doPost(url, location);
  }

  getLocationConfig(locationId: number): Promise<IHighLevelConfiguration> {
    const url = `/api/v10/servicelocation/${locationId}/highlevelconfiguration`;
    return this.client.doGet(url);
  }

  updateLocationConfig(locationId: number, measurements: IHighLevelConfiguration): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/highlevelconfiguration`;
    this.cache.highLevelConfigurations.invalidate(locationId);
    return this.client.doPost(url, measurements);
  }

  getLocationConfigs(serialNumber: string) {
    const url = `/api/v10/servicelocation/${serialNumber}/configurations?from=0&to=${Date.now()}`;
    return this.client.doGet(url);
  }

  updateLocation(locationId: number, location: IUpdateLocationRequest) {
    const url = `/api/v10/servicelocation/${locationId}`;
    this.caches.locations.invalidate(locationId);
    if (location.parentId !== undefined) {
      this.caches.chargingStations.chargingStationsByLocation.invalidate(location.parentId);
    }

    return this.client.doPatch(url, location);
  }

  updateLocationOpeningHours(locationId: number, openingHours: TimeRange[]) {
    const url = `/api/v10/servicelocation/${locationId}/openinghours`;
    this.caches.locations.invalidate(locationId);
    return this.client.doPut(url, openingHours);
  }

  updateLocationParent(locationId: number, parentId: number | null) {
    const url = `/api/v10/servicelocation/${locationId}/parent`;
    this.caches.locations.invalidate(locationId);
    if (parentId) {
      this.caches.chargingStations.chargingStationsByLocation.invalidate(parentId);
    }

    return this.client.doPut(url, {id: parentId});
  }

  attachCustomFileToLocation(locationId: number, file: ICustomFileFields): Promise<{} | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/customfiles`;
    return this.client.doPost(url, file);
  }

  setLocationActivationCode(locationId: number, code: string) {
    const url = `/api/v10/servicelocation/${locationId}/activationcode`;
    return this.client.doPut(url, code).then(result => {
      this.caches.locations.invalidate(locationId);
      this.cache.locationActivationCodes.invalidate(locationId);
      return result;
    });
  }

  getLocationActivationCode(locationId: number): Promise<IActivationCode | undefined> {
    return this.cache.locationActivationCodes.fetch(locationId);
  }

  internalGetLocationActivationCode(locationId: number): Promise<IActivationCode | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/activationcode`;
    return this.client.doGetWithNoContentAllowed(url);
  }

  getLocationMetaInfo(locationId: number): Promise<ILocationMetaInfo> {
    const url = `/api/v7/servicelocation/${locationId}/metainfo`;
    return this.client.doGet(url);
  }

  searchLocations(query: string, limit: number, includeObsolete = false): Promise<ILocationSearchResult[]> {
    let url = `/api/v10/search/${encodeURIComponent(query)}?limit=${limit}`;
    if (includeObsolete) url += '&includeObsolete=true';
    return this.client.doGet(url);
  }

  getSensors(locationId: number, refresh: boolean = false): Promise<IGasWaterDevice[]> {
    return this.cache.sensors.fetch(locationId, refresh);
  }

  getSensorsInternal(locationId: number): Promise<IGasWaterDevice[]> {
    const url = `/api/v10/servicelocation/${locationId}/sensors`;
    return this.client.doGet(url);
  }

  static createUsageTimestamps(from: number, to: number, interval: Interval): Array<number> {
    const timestamps = [];
    const unit = interval === Interval.MONTH ? 'months' : 'days';

    // Always use dayjs so timezone changes are considered
    while (from < to) {
      timestamps.push(from);
      from = dayjs(from).add(1, unit).valueOf();
    }

    return timestamps;
  }

  getSensorReadingsLegacy(
    locationId: number,
    sensorId: number,
    type: Interval,
    from: number,
    to: number
  ): Promise<{interval: Interval; readings: IGasWaterReading[]}> {
    const interval = getLegacyAggregationType(type);
    const path = `/api/v7/servicelocation/${locationId}/sensor/${sensorId}/readings?from=${from}&to=${to}&type=${interval}`;
    return this.client.doGet<IGasWaterReading[]>(path).then(readings => {
      const actualInterval = getAggregationTypeFromLegacy(interval);
      return {interval: actualInterval, readings};
    });
  }

  getSensorReadings(
    types: SensorReadingType[] | undefined,
    locationId: number,
    from: number,
    to: number,
    interval: Interval,
    sensorId?: number,
    channelId?: number
  ): Promise<ISensorReadingsMulti> {
    const range = `${from},${to + 1}`; // Plus 1 required on 'to' because call is exclusive
    const length = getUsageInterval(interval);
    let url = `/api/v10/servicelocation/${locationId}/usage?aggregationType=${interval}&intervalLength=${length}&range=${range}`;
    if (types !== undefined) url += `&types=${types.join(',')}`;
    if (sensorId) url += `&sensorId=${sensorId}`;
    if (channelId) url += `&channelId=${channelId}`;

    return this.client.doGet(url);
  }

  async getMeasuringReadings(
    types: string,
    locationId: number,
    from: number,
    to: number,
    interval: Interval,
    sensorId?: number
  ): Promise<IProcessedUsageList> {
    const timestamps = new Set(API.createUsageTimestamps(from, to, interval));
    const length = getUsageInterval(interval);
    const range = `${from},${to + 1}`; // Plus 1 required on 'to' because call is exclusive

    // Construct URL
    let url = `/api/v10/servicelocation/${locationId}/usage?types=${types}&aggregationType=${interval}&intervalLength=${length}&range=${range}`;
    url += sensorId ? `&sensorId=${sensorId}` : ``;

    // Fetch data
    const data = await this.client.doGet<IUsageList>(url);
    const {intervalLength: activeInterval, usages} = data;
    let items = new Map<number, IProcessedUsage>();
    let units: {[key: string]: UsageUnit} = {};

    // Construct compound objects
    if (Array.isArray(usages) && usages.length > 0) {
      for (let usage of usages) {
        let {type, intervals} = usage;

        // Fill in null values and determine the unit
        for (let interval of intervals) {
          let {timestamp, value, unit = UsageUnit.KiloWattHour} = interval;

          // Check if value is filled in
          const hasValue = value !== undefined && value !== null;

          // Get or create object
          let item: IProcessedUsage = items.get(timestamp) || {
            timestamp,
            unit,
            values: {}
          };
          item.values[type] = hasValue ? value! : null;
          item.timestamp = timestamp;
          item.unit = unit;

          // Specifically set item
          items.set(timestamp, item);

          // Skip if there is no value
          if (typeof value !== 'undefined') {
            // Remove occurring timestamp
            timestamps.delete(timestamp);
          }

          // Populate units for each usage once
          if (units[type] === undefined && unit) {
            units[type] = unit;
          }
        }
      }

      // Fill in requested timestamp gaps if data was returned
      if (items.size > 0) {
        timestamps.forEach(timestamp => {
          let item: IProcessedUsage = {
            timestamp,
            unit: UsageUnit.KiloWattHour,
            values: {}
          };

          // Create null value for each type
          for (let type of Object.keys(units)) {
            item.values[type] = null;
          }

          items.set(timestamp, item);
        });
      }
    }

    // Convert map values to array
    const values = Array.from(items.values());

    // Sort items by timestamp
    values.sort((a, b) => a.timestamp - b.timestamp);

    // Return object
    return {items: values, units, activeInterval};
  }

  getElectricityReadings(
    locationId: number,
    from: number,
    to: number,
    interval: Interval
  ): Promise<IProcessedUsageList> {
    const types = [UsageType.Electricity, UsageType.AlwaysOn, UsageType.Solar];
    return this.getMeasuringReadings(types.join(','), locationId, from, to, interval);
  }

  getGasReadings(
    locationId: number,
    from: number,
    to: number,
    interval: Interval,
    sensorId: number | undefined
  ): Promise<IProcessedUsageList> {
    return this.getMeasuringReadings(UsageType.Gas, locationId, from, to, interval, sensorId);
  }

  getWaterReadings(
    locationId: number,
    from: number,
    to: number,
    interval: Interval,
    sensorId: number | undefined
  ): Promise<IProcessedUsageList> {
    return this.getMeasuringReadings(UsageType.Water, locationId, from, to, interval, sensorId);
  }

  getChannelReadings(locationId: number, from: number, to: number, interval: Interval) {
    return this.getMeasuringReadings(UsageType.Channels, locationId, from, to, interval);
  }

  getPlugs(locationId: number) {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol`;
    return this.client.doGet(url).then(data => {
      const plugs = Array.isArray(data) ? data : [];
      plugs.sort((a, b) => a.label.localeCompare(b.label));
      return plugs;
    });
  }

  getSwitches(locationId: number, refresh: boolean = false): Promise<ISwitch[]> {
    return this.cache.switches.fetch(locationId, refresh);
  }

  getSwitchesInternal(locationId: number): Promise<ISwitch[]> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/controllablenodes`;
    return this.client.doGet(url).then(data => {
      const switches = Array.isArray(data) ? data : [];
      switches.sort((a, b) => a.name.localeCompare(b.name));
      return switches;
    });
  }

  executeSwitchAction(locationId: number, switchId: number, action: string) {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/controllablenodes/${switchId}/action`;
    return this.client.doPost(url, {action});
  }

  deleteAllData(locationId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/data`;
    return this.client.doDelete(url);
  }

  deleteElectricityData(locationId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/data/consumption`;
    return this.client.doDelete(url);
  }

  deleteApplianceData(locationId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/data/appliances`;
    return this.client.doDelete(url);
  }

  deleteAllGasWaterData(locationId: number) {
    const url = `/api/v10/servicelocation/${locationId}/data/sensors`;
    return this.client.doDelete(url);
  }

  deleteGasWaterData(locationId: number, sensorId: number) {
    const url = `/api/v10/servicelocation/${locationId}/data/sensors/${sensorId}`;
    return this.client.doDelete(url);
  }

  deleteAllSwitchData(locationId: number) {
    const url = `/api/v10/servicelocation/${locationId}/data/monitors`;
    return this.client.doDelete(url);
  }

  deleteSwitchData(locationId: number, sensorId: number) {
    const url = `/api/v10/servicelocation/${locationId}/data/monitors/${sensorId}`;
    return this.client.doDelete(url);
  }

  getInstallationPictures(locationId: number) {
    const url = `/api/v10/servicelocation/${locationId}/configuration/images`;
    return this.client.doGet(url).then(data => (Array.isArray(data) ? data : []));
  }

  getAvailableTimezones(): Promise<ITimezone[]> {
    const url = `/api/v10/servicelocation/timezones`;
    return this.client.doGet(url);
  }

  getPlugReadings(
    locationId: number,
    plugId: number,
    from: number,
    to: number,
    interval: Interval | string
  ): Promise<ISwitchReading[]> {
    // Map string-based intervals to legacy values
    if (isNaN(parseInt(interval))) {
      interval = INTERVAL_LEGACY_MAP[interval];
      interval = interval || INTERVAL_LEGACY_MAP[Interval.DAY];
    }

    const url = `/api/v10/servicelocation/${locationId}/monitor/${plugId}/readings?from=${from}&to=${to}&type=${interval}`;
    return this.client.doGet(url);
  }

  findUsers(query: string): Promise<IUser[]> {
    let url = `/api/v10/users`;
    if (query !== '') {
      url += `?filter=${encodeURIComponent(query)}`;
    }

    return this.client.doGet(url);
  }

  createUser(user: ICreateUserRequest): Promise<void> {
    const url = `/api/v10/users`;
    return this.client.doPost(url, user);
  }

  findOrCreateUser(user: IFindOrCreateUserRequest): Promise<IUser | undefined> {
    const url = `/api/v10/users`;
    return this.client.doPut(url, user);
  }

  updateUser(id: number, updates: Partial<IUser>): Promise<void> {
    const url = `/api/v10/users/${id}`;
    return this.client.doPatch(url, updates);
  }

  restoreUser(id: number): Promise<
    | {
        error?: string;
      }
    | undefined
  > {
    const url = `/api/v10/users/${id}/archive`;
    return this.client.doDelete(url, {});
  }

  removeUser(id: number): Promise<
    | {
        error?: string;
      }
    | undefined
  > {
    const url = `/api/v10/users/${id}`;

    return this.client.doDelete(url);
  }

  resetUserPassword(id: number, password: string): Promise<unknown> {
    const url = `/api/v10/users/${id}`;
    const data = {
      password
    };

    return this.client.doPatch(url, data);
  }

  clearAppActivationsForUser(id: number): Promise<unknown> {
    const url = `/api/v10/appactivation/user/${id}`;
    return this.client.doDelete(url);
  }

  getChannels(locationId: number, deviceType: DeviceType): Promise<ILoadChannel[]> {
    if (Device.isInfinity(deviceType)) {
      return this.getInfinityChannels(locationId);
    } else {
      return this.getLegacyChannelsX(locationId);
    }
  }

  getLegacyChannelsX(locationId: number): Promise<ILoadChannel[]> {
    return this.getLegacyChannels(locationId).then(channels => {
      return channels.map(legacyChannel => {
        const {channel, consumption, cttype, phase} = legacyChannel;
        let {name} = legacyChannel;
        if (name === undefined) name = `CT ${channel + 1}`;

        return {
          id: channel,
          publishIndex: channel,
          underConstruction: false,
          name,
          type: getLoadTypeFromConsumptionType(consumption),
          phase: PHASES[phase],
          ctType: cttype
        };
      });
    });
  }

  getLegacyChannels(locationId: number): Promise<ILegacyChannel[]> {
    const url = `/api/v10/installation/${locationId}/channelconfig`;
    return this.client.doGet<ILegacyChannel[]>(url).then(channels => {
      channels.forEach(channel => {
        if (!channel.name) {
          channel.name = `CT ${channel.channel}`;
        }
      });
      return channels;
    });
  }

  updateLegacyChannels(locationId: number, channels: ILegacyChannel[]): Promise<unknown> {
    const url = `/api/v10/installation/${locationId}/config/channels`;
    const data = channels.map(channel => ({
      balanced: channel.balanced,
      channel: channel.channel,
      connection: channel.connection,
      consumption: channel.consumption,
      cttype: channel.cttype,
      name: channel.name,
      nilm: channel.nilm,
      phase: channel.phase,
      reversed: channel.reversed,
      updates: channel.updates
    }));
    return this.client.doPut(url, data);
  }

  async getInfinityChannels(locationId: number): Promise<ILoadChannel[]> {
    const measurements = await this.getLoads(locationId);
    const channels: ILoadChannel[] = [];

    for (let measurement of measurements) {
      let {actuals, type, name} = measurement;
      for (let i = 0; i < actuals.length; i++) {
        let {phase, sensor, midBusAddress} = actuals[i];
        if (sensor === undefined && midBusAddress === undefined) continue;

        const channel = actuals[i];
        const phaseLabel = getPhaseLabel(phase);

        // Append phase to name if more than one
        let channelName = name || (type === LoadType.Grid ? 'Grid' : '');
        if (actuals.length > 1 && phaseLabel) {
          channelName += ` (${phaseLabel})`;
        }
        channel.name = channelName;
        channels.push(channel);
      }
    }

    return channels;
  }

  setProperty(locationId: number, key: string, value: string): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/properties`;
    return this.client.doPut(url, {key, value});
  }

  importFutechData(file: File): Promise<{data: any; status: number}> {
    const url = `/api/v10/futech`;
    return this.client.doUpload(url, file) as Promise<{data: any; status: number}>;
  }

  async getHighLevelConfigurationInternal(locationId: number): Promise<IHighLevelConfiguration> {
    const url = `/api/v10/servicelocation/${locationId}/highlevelconfiguration`;
    const resultPromise = this.client.doGet<IHighLevelConfiguration>(url).catch(err => {
      if (err.statusCode === 404) {
        const fallback: IHighLevelConfiguration = {
          locationId,
          underConstruction: false,
          phaseType: PhaseType.Single,
          nilm: false,
          measurements: None
        };
        return fallback;
      } else {
        throw err;
      }
    });

    const result = await resultPromise;
    result.locationId = locationId;
    return result;
  }

  getHighLevelConfiguration(locationId: number, refresh: boolean = false): Promise<IHighLevelConfiguration> {
    return this.cache.highLevelConfigurations.fetch(locationId, refresh);
  }

  getHighLevelConfigurationWithStations(locationId: number): Promise<IHighLevelConfiguration> {
    const url = `/api/v10/servicelocation/${locationId}/highlevelconfiguration?includeStations=true`;
    return this.client.doGet(url);
  }

  getLoads(locationId: number): Promise<Array<ILoad>> {
    return this.getHighLevelConfiguration(locationId).then(configuration =>
      configuration ? configuration.measurements || [] : []
    );
  }

  getConfiguration(locationId: number): Promise<IConfiguration> {
    const url = `/api/v10/servicelocation/${locationId}/configuration`;
    return this.client.doGet(url);
  }

  getDeviceInfo(serialNumber: string): Promise<IDeviceInfo> {
    const url = `/api/v10/devices/${serialNumber}`;
    return this.client.doGet(url);
  }

  getDataProcessing(locationId: number) {
    const url = `/api/v10/installation/${locationId}/config/dataprocessing`;
    return this.client.doGet(url);
  }

  createHighLevelMeasurement(locationId: number, load: ILoadCreationSpec): Promise<ILoad | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/highlevelconfiguration/measurements`;
    return this.client.doPost(url, load);
  }

  updateHighLevelMeasurement(
    locationId: number,
    id: number,
    measurement: ILoadUpdateResult
  ): Promise<{success: boolean}> {
    this.cache.highLevelConfigurations.invalidate(locationId);

    const url = `/api/v10/servicelocation/${locationId}/highlevelconfiguration/measurements/${id}`;
    return this.client.doPatchWithStatus(url, measurement);
  }

  deleteHighLevelMeasurement(locationId: number, id: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/highlevelconfiguration/measurements/${id}`;
    return this.client.doDelete(url);
  }

  updateActualMeasurement(
    locationId: number,
    id: number,
    measurement: IChannelUpdateResult
  ): Promise<{success: boolean}> {
    this.cache.highLevelConfigurations.invalidate(locationId);

    const url = `/api/v10/servicelocation/${locationId}/configuration/measurements/${id}`;
    return this.client.doPatchWithStatus(url, measurement);
  }

  completeLoads(locationId: number) {
    const url = `/api/v10/servicelocation/${locationId}/configuration/complete`;
    return this.client.doPostWithStatus(url, '');
  }

  preconfigureKit(kit: IPreconfigurationKit): Promise<ValidationError[]> {
    const url = `/api/v10/preconfiguration`;
    return this.client.doPost<ValidationError[]>(url, kit).then(result => result || []);
  }

  resetPreconfiguration(gatewaySerialNumber: string): Promise<IValidationError | undefined> {
    const url = `/api/v10/preconfiguration/${gatewaySerialNumber}`;
    return this.client.doDelete(url);
  }

  getHomeControlDevices(locationId: number): Promise<IHomeControlDevices> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/devices`;
    return this.client.doGet(url);
  }

  getSmartDevices(locationId: number): Promise<ISmartDevice[]> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/devices`;
    return this.client.doGet(url);
  }

  getAllSmartDevices(locationId: number): Promise<ISmartDevice[]> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/devices?excludedCategories=`;
    return this.client.doGet(url);
  }

  getCapacityProtection(locationId: number): Promise<ICapacityProtectionConfiguration> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/capacityprotection`;
    return this.client.doGet(url);
  }

  updateCapacityProtection(
    locationId: number,
    config: ICapacityProtectionConfiguration
  ): Promise<ICapacityProtectionConfiguration | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/capacityprotection`;
    return this.client.doPut(url, config);
  }

  getSmartDevice(locationId: number, deviceId: string): Promise<ISmartDevice | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/devices/${deviceId}`;
    return this.client.doGet(url);
  }

  updateSmartDevice(
    locationId: number,
    deviceId: string,
    device: Partial<ISmartDevice>
  ): Promise<ISmartDevice | undefined> {
    return this.smartDevices.update(locationId, deviceId, device);
  }

  getOverloadProtection(locationId: number): Promise<IOverloadProtectionConfigMultiGrid> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/overloadprotection`;
    return this.client.doGet(url);
  }

  updateOverloadProtection(
    locationId: number,
    updates: IUpdateOverloadProtectionConfigurationRequest
  ): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/overloadprotection`;
    return this.client.doPatch(url, updates);
  }

  getAutomations(locationId: number, types?: string[]): Promise<Automation[]> {
    let url = `/api/v10/servicelocation/${locationId}/homecontrol/automations`;
    if (types) url += `?excludedCategories=${types.join(',')}`;

    return this.client.doGet(url);
  }

  getAutomationsForSmart(locationId: number, categories?: string[]): Promise<Automation[]> {
    let url = `/api/v10/servicelocation/${locationId}/homecontrol/automations/smart`;
    if (categories) url += `?includedCategories=${categories.join(',')}`;

    return this.client.doGet(url);
  }

  getAutomationTypes(locationId: number): Promise<string[]> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/automations/types`;
    return this.client.doGet(url);
  }

  getAutomationTargets(locationId: number, excludedCategories?: string[]): Promise<IAutomationTarget[]> {
    let url = `/api/v10/servicelocation/${locationId}/homecontrol/automations/targets`;
    if (excludedCategories) {
      url += `?excludedCategories=${excludedCategories.join(',')}`;
    }

    return this.client.doGet(url);
  }

  createAutomation(locationId: number, automation: Automation): Promise<Automation | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/automations`;
    return this.client.doPost(url, automation);
  }

  getAutomation(locationId: number, automationId: number): Promise<Automation> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/automations/${automationId}`;
    return this.client.doGet(url);
  }

  updateAutomation(
    locationId: number,
    automationId: number,
    automation: Partial<Automation>
  ): Promise<Automation | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/automations/${automationId}`;
    return this.client.doPatch(url, automation);
  }

  deleteAutomation(locationId: number, automationId: number) {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/automations/${automationId}`;
    return this.client.doDelete(url);
  }

  async updateAutomationIncludingActions(
    locationId: number,
    automationId: number,
    oldAutomation: Automation,
    newAutomation: Automation
  ): Promise<Automation | undefined> {
    const oldScene = oldAutomation.scene;
    const newScene = newAutomation.scene;
    if (oldScene && newScene) {
      const removedActions = oldScene.actions.filter(action => !newScene.actions.some(a => a.id === action.id));
      for (let action of removedActions) {
        await this.deleteAutomationAction(locationId, automationId, action.id);
      }
      for (let action of newScene.actions) {
        if (action.id === undefined) {
          await this.createAutomationAction(locationId, automationId, action);
        } else {
          await this.updateAutomationAction(locationId, automationId, action);
        }
      }
    }

    return this.updateAutomation(locationId, automationId, newAutomation);
  }

  createAutomationAction(locationId: number, automationId: number, action: AutomationAction): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/automations/${automationId}/actions`;
    return this.client.doPost(url, action);
  }

  updateAutomationAction(locationId: number, automationId: number, action: AutomationAction): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/automations/${automationId}/actions/${action.id}`;
    return this.client.doPatch(url, action);
  }

  deleteAutomationAction(locationId: number, automationId: number, actionId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/automations/${automationId}/actions/${actionId}`;
    return this.client.doDelete(url);
  }

  getUserPreferences(): Promise<IUserPreference[]> {
    const url = `/api/v10/portal/preferences`;
    return this.client.doGet(url);
  }

  setUserPreference(key: string, value: string): Promise<{key: string; value: string}> {
    const url = `/api/v10/portal/preferences/${key}`;
    return this.client.doPut<{key: string; value: string}>(url, value).then(result => result || {key, value});
  }

  deleteUserPreference(key: string): Promise<unknown> {
    const url = `/api/v10/portal/preferences/${key}`;
    return this.client.doDelete(url);
  }

  createAPIToken(appName: string, clientId: string, clientSecret: string) {
    const url = `/api/v7/portal/register?app_name=${appName}&client_id=${clientId}&client_secret=${clientSecret}&redirect_uri=`;
    return this.client.doGetWithStatus(url);
  }

  getDeviceIncidents(serialNumber: string): Promise<IDeviceIncident[]> {
    const url = `/api/v10/installation/${serialNumber}/incidents/`;
    return this.client.doGet(url);
  }

  getDeviceActivationCode(serialNumber: string): Promise<IActivationCode | undefined> {
    const url = `/api/v10/devices/${serialNumber}/activationcode`;
    // the call can fail when performed on a froggee device - catch it here
    return this.client.doGet<IActivationCode | undefined>(url).catch(() => undefined);
  }

  findServiceLocationsByEmail(email: string): Promise<IQueriedLocationUser[]> {
    if (email.length < 3) return Promise.resolve([]);

    const url = `/api/v10/search/locations?email=${encodeURIComponent(email)}&limit=100`;
    return this.client.doGet(url);
  }

  findServiceLocationsByUsername(username: string): Promise<IQueriedLocationUser[]> {
    if (username.length < 3) return Promise.resolve([]);

    const url = `/api/v10/search/locations?userName=${encodeURIComponent(username)}&limit=100`;
    return this.client.doGet(url);
  }

  getLocationSurvey(locationId: number): Promise<ILocationSurvey> {
    const url = `/api/v10/servicelocation/${locationId}/survey`;
    return this.client.doGet(url);
  }

  sendInAppMessage(
    locationId: number,
    message: string,
    selectedUser: string | undefined,
    context?: IPushMessageContext
  ) {
    const url = `/api/v10/servicelocation/${locationId}/messages`;
    const delivery: {all?: boolean; userNames?: string[]; userIds?: number[]} = {};

    if (selectedUser !== undefined) {
      if (hasFeature(AppFeature.SocialLogin)) {
        delivery.userIds = [parseInt(selectedUser)];
      } else {
        delivery.userNames = [selectedUser as string];
      }
    } else {
      delivery.all = true;
    }

    return this.client.doPost(url, {
      text: message,
      delivery,
      context
    });
  }

  getDeviceConfigurationHistory(
    serialNumber: string,
    from: number = 0,
    to: number = Date.now()
  ): Promise<IDeviceConfigurationState[]> {
    const url = `/api/v10/installation/${serialNumber}/configurations?from=${from}&to=${to}`;
    // TODO: workaround: configurations returns nothing instead of an empty array if there is no data available. This will be fixed with the socketcluster branch.
    //return this.apiEndpoint.get(path, {from: from || 0, to: to || new Date().getTime() }, DeviceConfiguration.listDeserializer);
    return this.client.doGet<IDeviceConfigurationState[]>(url).then(configurations => configurations || []);
  }

  getDeviceActivationHistory(serialNumber: string, includeObsolete: boolean) {
    let url = `/api/v10/devices/${serialNumber}/history`;
    if (includeObsolete) url += '?includeObsolete=true';

    return this.client.doGet<IDeviceActivationHistoryEntry[]>(url);
  }

  async getCurrentConfiguration(serialNumber: string): Promise<IDeviceConfiguration> {
    const results = await this.getDeviceConfigurationHistory(serialNumber);
    if (results.length === 0) return {};

    // Status enum values (search 'enum Status' in backend)
    const PENDING = 0;
    //    const SYNCED = 1;
    const ACTUAL = 2;
    //    const OBSOLETE = 3;

    let result: IDeviceConfiguration = {};
    if (results[0].status === PENDING) {
      result.pending = results[0];
      result.actual = results.find(result => result.status === ACTUAL);
    } else if (results[0].status === ACTUAL) {
      result.actual = results[0];
    }
    return result;
  }

  getUserQuestions(locationId: number): Promise<unknown> {
    const url = `/api/v7/installation/${locationId}/questions`;
    return this.client.doGet(url);
  }

  getNilmVersion(locationId: number): Promise<{version: number | undefined}> {
    const url = `/api/v10/servicelocation/${locationId}/nilmversion`;
    return this.client.doGet<{version: number | undefined}>(url).catch(() => ({version: undefined}));
  }

  getOrganizationSearchFields(organizationId: number): Promise<ISearchField[] | undefined> {
    const url = `/api/v10/organizations/${organizationId}/custom`;
    return this.client
      .doGet<ISearchField[] | undefined>(url)
      .then(fields => (fields || []).sort((a, b) => a.sequenceNumber - b.sequenceNumber));
  }

  setOrganizationSearchField(organizationId: number, field: ISearchField): Promise<any> {
    const url = `/api/v10/organizations/${organizationId}/custom/${field.name}`;
    return this.client.doPut(url, field);
  }

  deleteOrganizationSearchField(organizationId: number, name: string): Promise<any> {
    const url = `/api/v10/organizations/${organizationId}/custom/${name}`;
    return this.client.doDelete(url);
  }

  getLocationSearchFieldValues(locationId: number): Promise<ISearchFieldValue[]> {
    const url = `/api/v10/servicelocation/${locationId}/custom`;
    return this.client.doGet(url);
  }

  getLocationSearchFieldValue(locationId: number, property: string): Promise<ISearchFieldValue> {
    const url = `/api/v10/servicelocation/${locationId}/custom/${property}`;
    return this.client.doGet(url);
  }

  setLocationSearchFieldValue(locationId: number, property: string, value: string | number | boolean) {
    const url = `/api/v10/servicelocation/${locationId}/custom/${property}`;
    return this.client.doPut(url, {value});
  }

  deleteLocationSearchFieldValue(locationId: number, property: string) {
    const url = `/api/v10/servicelocation/${locationId}/custom/${property}`;
    return this.client.doDelete(url);
  }

  getRoleTypes(): Promise<IRoleType[]> {
    if (this.cache.roleTypes === undefined) {
      const url = `/api/v10/organizations/users/roletypes`;
      this.cache.roleTypes = this.client.doGet(url);
    }

    return this.cache.roleTypes;
  }

  searchLocationsBySearchField(query: {[field: string]: string}, limit: number = 1000): Promise<ILocationSummary[]> {
    let queryParameters: string[] = [];
    for (var key in query) queryParameters.push(`${key}=${query[key]}`);

    const url = `/api/v10/search/custom?${queryParameters.join('&')}&__limit=${limit}`;
    return this.client.doGet(url);
  }

  setOutputModuleState(
    locationId: number,
    outputModuleSerial: string,
    controlId: number,
    value: IDeviceOutputConfiguration
  ): Promise<{id: string; name: string} | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/output/${outputModuleSerial}/controls/${controlId}/state`;
    return this.client.doPut(url, {
      id: value.id,
      name: value.name
    });
  }

  getCustomFiles(locationId: number): Promise<ICustomFile[]> {
    const url = `/api/v10/servicelocation/${locationId}/customfiles`;
    return this.client.doGet(url);
  }

  createCustomFile(locationId: number, file: ICustomFileFields): Promise<ICustomFile | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/customfiles`;
    return this.client.doPost(url, file);
  }

  getCustomFile(locationId: number, id: number): Promise<ICustomFile> {
    const url = `/api/v10/servicelocation/${locationId}/customfiles/${id}`;
    return this.client.doGet(url);
  }

  deleteCustomFile(locationId: number, id: number): Promise<void> {
    const url = `/api/v10/servicelocation/${locationId}/customfiles/${id}`;
    return this.client.doDelete(url);
  }

  uploadFile(locationId: number, file: File): Promise<IUploadedFile[]> {
    const url = `/imageserver/${locationId}?fileName=${encodeURIComponent(file.name)}`;
    return this.client.uploadFile(url, file);
  }

  uploadBlob(locationId: number, content: Blob, name: string): Promise<IUploadedFile[]> {
    const url = `/imageserver/${locationId}?fileName=${encodeURIComponent(name)}`;
    return this.client.uploadBlob(url, content, name);
  }

  uploadChargingStationImage(
    serialNumber: string,
    file: File,
    type: ChargingDisplayImageType
  ): Promise<IUploadedFile[]> {
    const url = `/api/v10/chargingstations/${serialNumber}/images/${type}`;
    return this.client.uploadFile(url, file, 'file');
  }

  deleteChargingStationImage(serialNumber: string, type: ChargingDisplayImageType): Promise<ChargingDisplayImageType> {
    const url = `/api/v10/chargingstations/${serialNumber}/images/${type}`;
    return this.client.doDelete(url);
  }

  getChargingStationImages(serialNumber: string): Promise<ChargingDisplayImage[]> {
    const url = `/api/v10/chargingstations/${serialNumber}/images`;
    return this.client.doGet(url);
  }

  uploadChargingParkImage(
    serviceLocationId: number,
    file: File,
    type: ChargingDisplayImageType
  ): Promise<IUploadedFile[]> {
    const url = `/api/v10/chargingparks/${serviceLocationId}/images/${type}`;
    return this.client.uploadFile(url, file, 'file');
  }

  deleteChargingParkImage(
    serviceLocationId: number,
    type: ChargingDisplayImageType
  ): Promise<ChargingDisplayImageType> {
    const url = `/api/v10/chargingparks/${serviceLocationId}/images/${type}`;
    return this.client.doDelete(url);
  }

  getChargingParkImages(serviceLocationId: number): Promise<ChargingDisplayImage[]> {
    const url = `/api/v10/chargingparks/${serviceLocationId}/images`;
    return this.client.doGet(url);
  }

  getOrganizationRegions(organizationId: number): Promise<IOrganizationRegion[]> {
    const url = `/api/v10/organizations/${organizationId}/regions`;
    return this.client.doGet(url);
  }

  getOrganizationRegion(organizationId: number, id: number): Promise<IOrganizationRegion> {
    const url = `/api/v10/organizations/${organizationId}/regions/${id}`;
    return this.client.doGet(url);
  }

  getMeasuringCases(organizationId: number): Promise<IMeasuringCase[]> {
    const url = `/api/v10/organizations/${organizationId}/measuringcases`;
    return this.client.doGet(url);
  }

  createChargingStation(request: CreateChargingStationRequest): Promise<CreateChargingStationResult | undefined> {
    const url = `/api/v10/chargingstations`;
    return this.client.doPost(url, request);
  }

  deleteChargingStation(serialNumber: string): Promise<unknown> {
    const url = `/api/v10/chargingstations/${serialNumber}`;
    return this.client.doDelete(url);
  }

  deleteChargingStationInstallation(parentId: number, childId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${parentId}/childs/${childId}`;
    return this.client.doDelete(url);
  }

  updateChargingStationControllers(chargingStationSerial: string, updates: IChargingStationConfiguration) {
    const url = `/api/v10/chargingstations/${chargingStationSerial}/configuration`;
    return this.client.doPut(url, updates);
  }

  replaceChargingStationModule(
    chargingStationSerial: string,
    moduleId: number,
    serialNumber: string
  ): Promise<unknown> {
    const url = `/api/v10/chargingstations/${chargingStationSerial}/modules/${moduleId}/replace`;
    return this.client.doPost(url, {serialNumber});
  }

  replaceChargingStationModuleWithOther(
    chargingStationSerial: string,
    moduleId: number,
    serialNumber: string,
    otherChargingStationSerial: string
  ): Promise<unknown> {
    const url = `/api/v10/chargingstations/${chargingStationSerial}/modules/${moduleId}/swap`;
    this.caches.chargingStations.invalidateSerial(chargingStationSerial);
    this.caches.chargingStations.invalidateSerial(otherChargingStationSerial);
    return this.client.doPost(url, {
      otherModuleSerialNumber: serialNumber,
      otherChargingStation: {serialNumber: otherChargingStationSerial}
    });
  }

  removeChargingStationModule(chargingStationSerial: string, moduleId: number): Promise<unknown> {
    const url = `/api/v10/chargingstations/${chargingStationSerial}/modules/${moduleId}`;
    return this.client.doDelete(url);
  }

  createChargingStationModule(
    chargingStationSerial: string,
    request: IChargingStationCreateModuleRequest
  ): Promise<unknown> {
    const url = `/api/v10/chargingstations/${chargingStationSerial}/modules`;
    return this.client.doPost(url, request);
  }

  getChargingStationSessions(chargingStationSerial: string, from: number, to: number): Promise<IChargingSession[]> {
    const url = `/api/v10/chargingstations/${chargingStationSerial}/sessions?range=${from},${to}&rangeMode=stop_or_start`;
    return this.client.doGet(url);
  }

  getChargingParkSessions(serviceLocationId: number, from: number, to: number): Promise<IChargingSession[]> {
    const url = `/api/v10/chargingparks/${serviceLocationId}/sessions?range=${from},${to}&rangeMode=stop_or_start`;
    return this.client.doGet(url);
  }

  getAllChargingSessions(
    from: number,
    to: number,
    paymentTypes?: ChargingStationPaymentTypeFilter[]
  ): Promise<ChargingSessionExport[]> {
    let url = `/api/v10/chargingsessions/json?from=${from}&to=${to}`;
    if (paymentTypes !== undefined) {
      if (paymentTypes.length === 0) return Promise.resolve([]);

      url += `&types=${paymentTypes.join(',')}`;
    }

    return this.client.doGet(url);
  }

  getChargingStationDiagnostics(
    chargingStationSerial: string,
    from: number,
    to: number
  ): Promise<IChargingStationDiagnosticEvent[]> {
    const url = `/api/v10/chargingstations/${chargingStationSerial}/diagnostics?range=${from},${to}`;
    return this.client.doGet(url);
  }

  getCloudVersion(): Promise<string> {
    const url = `/api/v10/cloud`;
    return this.client.doGetText(url);
  }

  startCharging(serviceLocation: number, deviceId: string, properties: IConfigurationProperty[]): Promise<unknown> {
    return this.runSmartDeviceAction(serviceLocation, deviceId, 'startCharging', properties);
  }

  runSmartDeviceAction(
    serviceLocation: number,
    deviceId: string,
    action: string,
    properties: IConfigurationProperty[]
  ): Promise<unknown> {
    const url = `/api/v10/servicelocation/${serviceLocation}/homecontrol/smart/devices/${deviceId}/actions/${action}`;
    return this.client.doPost(url, properties);
  }

  setPercentageLimit(
    serviceLocation: number,
    deviceId: string,
    properties: IConfigurationProperty[]
  ): Promise<unknown> {
    const url = `/api/v10/servicelocation/${serviceLocation}/homecontrol/smart/devices/${deviceId}/actions/setPercentageLimit`;
    return this.client.doPost(url, properties);
  }

  getDefaultChargingSettings(serviceLocation: number): Promise<{priority: number; discount: number}> {
    const url = `/api/v10/servicelocation/${serviceLocation}/chargesettings`;
    return this.client.doGet(url);
  }

  setDefaultChargingSettings(serviceLocation: number, priority: number, discount: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${serviceLocation}/chargesettings`;
    return this.client.doPut(url, {priority, discount});
  }

  setUserChargingSettings(
    serviceLocation: number,
    user: string | number,
    priority: number,
    discount: number
  ): Promise<unknown> {
    const url = `/api/v10/servicelocation/${serviceLocation}/chargesettings/users/${user}`;
    return this.client.doPut(url, {priority, discount});
  }

  deleteUserChargingSettings(serviceLocation: number, userId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${serviceLocation}/chargesettings/users/${userId}`;
    return this.client.doDelete(url);
  }

  getChargingSettings(serviceLocation: number): Promise<IChargingRule[]> {
    const url = `/api/v10/servicelocation/${serviceLocation}/chargesettings/all`;
    return this.client.doGet(url);
  }

  getRFIDChargingSettings(serviceLocation: number): Promise<IChargingRule[]> {
    const url = `/api/v10/servicelocation/${serviceLocation}/chargesettings/rfids`;
    return this.client.doGet(url);
  }

  setRFIDChargingSettings(
    serviceLocation: number,
    tag: string,
    expiration?: number,
    comment?: string
  ): Promise<unknown> {
    const url = `/api/v10/servicelocation/${serviceLocation}/chargesettings/rfids/${tag}`;
    return this.client.doPut(url, {
      discount: 0,
      rfid: {expirationTimestamp: expiration, comment}
    });
  }

  getChargingStationInternalConditions(
    serialNumber: string,
    from: number,
    to: number,
    interval: Interval
  ): Promise<IChargingStationInternalCondition[]> {
    if (interval === Interval.MINUTES_5) interval = Interval.INTERVAL;

    const url = `/api/v10/chargingstations/${serialNumber}/internalconditions?range=${from},${to}&aggregationType=${interval}`;
    return this.client.doGet(url);
  }

  deleteRFIDChargingSettings(serviceLocation: number, tag: string) {
    const url = `/api/v10/servicelocation/${serviceLocation}/chargesettings/rfids/${tag}`;
    return this.client.doDelete(url);
  }

  getBatteryManufacturers(serviceLocation: number): Promise<IBatteryManufacturer[]> {
    const url = `/api/v10/servicelocation/${serviceLocation}/batteries/manufacturers`;
    return this.client.doGet(url);
  }

  printKitLabel(articleCode: string, serialNumber: string, printer: string) {
    return this.client.doGetWithNoContentAllowed(
      `/dashapi/printing/kitlabel/${articleCode}?serial=${serialNumber}&printer=${printer}&token=${this.client.getToken()}`
    );
  }

  getMySplitBillingAgreements(): Promise<SplitBillingAgreement[]> {
    const url = `/api/v10/splitbilling/agreements/my`;
    return this.client.doGet(url);
  }

  createSplitBillingAgreement(request: SplitBillingAgreementCreateRequest): Promise<unknown> {
    const url = `/api/v10/splitbilling/agreements`;
    return this.client.doPost(url, request);
  }

  getSplitBillingAgreement(code: string): Promise<SplitBillingAgreement> {
    const url = `/api/v10/splitbilling/agreements/${code}`;
    return this.client.doGet(url);
  }

  confirmSplitBillingAgreement(code: string): Promise<unknown> {
    const url = `/api/v10/splitbilling/agreements/${code}/confirm`;
    return this.client.doPost(url, {});
  }

  completeSplitBillingAgreement(code: string, updates: Partial<SplitBillingAgreementCreateRequest>) {
    const url = `/api/v10/splitbilling/agreements/${code}/complete`;
    return this.client.doPost(url, updates);
  }

  getSplitBillingChargingSessions(agreementId: number): Promise<IChargingSession[]> {
    const url = `/api/v10/splitbilling/agreements/${agreementId}/sessions`;
    return this.client.doGet(url);
  }

  updateSplitBillingAgreement(id: number, updates: Partial<SplitBillingAgreementCreateRequest>) {
    const url = `/api/v10/splitbilling/agreements/${id}`;
    return this.client.doPatch(url, updates);
  }

  checkSplitBillingAgreementDeletable(id: number): Promise<boolean> {
    const url = `/api/v10/splitbilling/agreements/${id}/deletable`;
    return this.client.doGet(url, true);
  }

  deleteSplitBillingAgreement(id: number): Promise<unknown> {
    const url = `/api/v10/splitbilling/agreements/${id}`;
    return this.client.doDelete(url);
  }

  resendSplitBillingAgreementConfirmation(id: number): Promise<unknown> {
    const url = `/api/v10/splitbilling/agreements/${id}/resendconfirmation`;
    return this.client.doPost(url, {});
  }

  getSpotPriceMarkets(): Promise<SpotPriceMarket[]> {
    const url = `/api/v10/spotprices`;
    return this.client.doGet(url);
  }
}

export interface IUpdateCardRequest {
  type: string;
  name: string | null;
  settings: any;
  version: number;
  order: number;
}

export default API;
