import {isAPIResponse} from '../../api/APIClient';
import {ChargingCardInvitation, Invitation} from '../../models/Invitation';
import {RFIDCard} from '../../models/RFIDCard';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';

export interface ChargingCardEntry {
  id: string;
  value: string;
  name: string;
  username?: string;
  userFirstName?: string;
  userLastName?: string;
  employeeNumber?: string;
  card?: RFIDCard;
  invitation?: ChargingCardInvitation;
  expired: boolean;
  accepted: boolean;
}

export function getChargingCardEntries(cards: RFIDCard[], invitations: ChargingCardInvitation[]): ChargingCardEntry[] {
  const cardEntries: ChargingCardEntry[] = cards.map(card => ({
    id: `C${card.id}`,
    value: card.value,
    name: card.name,
    username: card.username,
    userFirstName: card.userFirstName,
    userLastName: card.userLastName,
    employeeNumber: card.employeeNumber,
    card,
    expired: false,
    accepted: true
  }));
  const invitationEntries: ChargingCardEntry[] = invitations.map(invitation => ({
    id: `I${invitation.id}`,
    value: invitation.rfid.value,
    name: invitation.rfid.name,
    userFirstName: invitation.invitee?.firstName,
    userLastName: invitation.invitee?.lastName,
    emailAddress: invitation.invitee?.emailAddress,
    employeeNumber: invitation.rfid.employeeNumber,
    invitation,
    expired: invitation.expired,
    accepted: false
  }));
  return cardEntries.concat(invitationEntries);
}

export function getCreateCardError(err: unknown, username?: string, email?: string): [string, string] {
  const userNameField = hasFeature(AppFeature.SocialLogin) ? 'email' : 'username';
  if (isAPIResponse(err)) {
    if (err.code === 'duplicate.token') {
      return ['rfid', T('publicChargingTokens.exists.inUse')];
    } else if (err.code === 'user.invalid') {
      return [userNameField, T('splitBilling.field.user.invalid')];
    } else if (err.code === 'user.duplicate') {
      return [userNameField, T('splitBilling.field.user.duplicate')];
    } else if (err.code === 'username.exists') {
      return [userNameField, T('users.error.alreadyInUse', {userName: username || ''})];
    } else if (err.code === 'email.exists') {
      return ['email', T('users.error.emailAlreadyInUse', {userName: email || ''})];
    }
  }
  return ['', translateError(err)];
}
